import React, { Fragment, useState , useEffect } from 'react';
import { UncontrolledTooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { TextField } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {Autocomplete} from '@material-ui/lab';
//import { toast } from 'react-toastify';
import { indianNumberFormat, usNumberFormat } from '../../../actions/numberFormat';
import ViewEmployee from './ViewEmployee';
import { getEmployee, getDepartment, getDesignation, getCompensation, getPayroll, getGroup } from '../../../actions/asyncActions';
import PrintPayslip from './PrintPayslip.js';
import { toast } from 'react-toastify';

const EmployeeMaster2 = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
	const group_id = user.group_id;
	const [group, setGroup] = useState([]);
	const groupDetails = group.find(x=> x.id === group_id);
	const companyName = groupDetails ? groupDetails && groupDetails.name : null;

   const [employees, setEmployees] = useState([]);
   const filterEmployee = employees.filter(x=> x.id === user.emp_id);
   
   const [department, setDepartment] = useState([]);
   const [designation, setDesignation] = useState([]);
   const [compensation, setCompensation] = useState([]);
   const [payroll, setPayroll] = useState([]);

    useEffect( () => {
	  getEmployee(group_id)
	  .then(resp => setEmployees(resp.data))
	}, []);
	
	useEffect( () => {
	  getDepartment(group_id)
	  .then(resp => setDepartment(resp.data))
	}, []);

	useEffect( () => {
	  getDesignation(group_id)
	  .then(resp => setDesignation(resp.data))
	}, []);

	useEffect( () => {
		getCompensation(group_id)
		.then(resp => setCompensation(resp.data))
	}, []);

	useEffect( () => {
		getPayroll(group_id)
		.then(resp => setPayroll(resp.data))
	}, []);

	useEffect( () => {
		getGroup(group_id)
		.then(resp => setGroup(resp.data))
	}, []);
    
	const viewEmployee = (n) => {
		const Id = n.id
		props.history.push(`${process.env.PUBLIC_URL}/viewemployee`, {Id} )
	}

	const viewCompensation = (id) => {
		const filterCompensation = compensation.filter(x=>x.emp_id === id);
		props.history.push(`${process.env.PUBLIC_URL}/viewcompensation`, {id, filterCompensation})
	}

	const zzz = payroll.filter(x => x.emp_id === user.emp_id); //Filter specific Emp Data
	const abcd = [...new Set(zzz.map(x => x.month))]; // Get all the months
	const pqrs = [...new Set(zzz.map(x => x.year))]; // Get all the months
	let payslipMonths = [];
	abcd.forEach((item, i) => {
		payslipMonths.push({
			name: item
		})
	})
	const [month_val, setMonthVal] = useState(null)
	const selectedMonth = month_val && month_val.name;

	let payslipYears = [];
	pqrs.forEach((item, i) => {
		payslipYears.push({
			name: item
		})
	})
	const [year_val, setYearVal] = useState(null)
	const selectedYear = year_val && year_val.name;

	const payslipData = selectedMonth ? 
		payroll.filter(x => x.emp_id === user.emp_id && x.month === selectedMonth && x.year === selectedYear) 
		: null;
	
	const printPayslipCheck = () => {
		if(!selectedMonth || !selectedYear){
			toast.warning("Month/Year is not selected!!!", {position: toast.POSITION.TOP_RIGHT, autoClose:3000})
		}
		else{
			handlePrintPayslip()
			setMonthVal(null)
			setYearVal(null)
		}
	}
	
	const handlePrintPayslip = () => {
		var mywindow = window.open('', 'PRINT', 'height=400,width=600');
		mywindow.document.write(`<html><head><title>${document.title}</title>
		 <style>
		 @page { margin: 0 }
		  body { margin: 0 }
		  .sheet {
		  margin: 0;
		  overflow: hidden;
		  position: relative;
		  box-sizing: border-box;
		  page-break-after: always;
		  }
	
		  /* Padding area */
		  .sheet.padding-5mm { padding: 5mm }
	
		  /* For screen preview */
		  @media screen {
		  body { background: #e0e0e0 }
			.sheet {
			 background: white;
			 box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
			 margin: 2mm;
		   }
		   table {
			  border-collapse: collapse;
		  }
		   tr.border_bottom td {
			border-bottom:1pt solid black;
		  }
		  table th, table td {
		  border:1px solid #000;
			padding: 0.5em;
		  }
		  }
		  @media print {
			  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td {
			  font-size: 9pt !important;
				   }
				}
	
		 </style>
		 `);
	   mywindow.document.write('</head><body>');
	   mywindow.document.write(document.getElementById('printreceipt').innerHTML);
	   mywindow.document.write('</body></html>');
	
	   mywindow.document.close(); // necessary for IE >= 10
	   mywindow.focus(); // necessary for IE >= 10*/
	
	   mywindow.print();
	   mywindow.close();
	
	   return true;
	}

	return(
		<Fragment>
		<div style={{display:"none"}}>
			<PrintPayslip
				companyName = {companyName}
				selectedMonth = {selectedMonth}
				selectedYear = {selectedYear}
				employeeId = {user.emp_id}
				payslipData = {payslipData}
			/>
		</div>
        {filterEmployee ? 
		 <div className="container-fluid">
            <div className="row">
              {/*
               <div className="col-xl-12">
                <input type="text" placeholder="Search Invoice" style={elementStyle} value={search}
	              onChange={e => setSearch(e.target.value)} />
               </div>
              */}
               <div className="col-xl-12">
                 <div className="row">
                  {filterEmployee.map((n, i) => {
					const desn = designation.find(x=> x.id === n.desg_id)
					const desnName = desn ? desn && desn.name : null;
                   
                   return (
                   	 <div className="col-sm-12 col-xl-6 col-md-6">
			            <div className="ribbon-wrapper card">
			              <div className="card-body">
			                <div className="ribbon ribbon-bookmark ribbon-secondary">
								{n.employee_id}
							</div>
			                <div className="row text-center">
			                 <div className="col-sm-12 col col-md-12">
                            { n.gender === 'male' ?
                              <i className="fa fa-male" style={{fontSize: '50px', color:'#4bb543'}}></i>
                              :
                              <i className="fa fa-female" style={{fontSize: '50px', color:'#4bb543'}}></i>
                            }
                             <p>
			                  Name: <b>{n.name}</b><br/>
			                  Mobile No: <b>{n.phone}</b><br/>
			                  Email: <b> {n.email}</b><br/>
							  Designation: <b>{desnName}</b> <br/>
							  Status: 
							  { n.status === 'Submitted' ? 
							   <b>Form Submitted</b> 
							   : n.status === 'Approved' ?
								n.compensation === 'yes' ?
								<b>Approved and Compensation Added </b>
								:
							   <b>Approved</b> 
							   :
							   <b></b>
							  }
			                 </p>
								{n.status === 'Submitted' || n.status === 'Approved' ?
								 <i className="fa fa-eye" onClick={() => viewEmployee(n)} id="TooltipTop" data-placement="top"
									style={{ width: 35, fontSize: 26, padding: 11, color: 'blue' }}>
									<UncontrolledTooltip placement="top" target="TooltipTop">
										View/Edit Details
									</UncontrolledTooltip>
								</i>
								:
								<i></i>
								}
								{ n.status === 'Approved' && n.compensation === 'yes'?
								 <i className="fa fa-money" onClick={() => viewCompensation(n.id)} id="TooltipTop2" data-placement="top"
									style={{ width: 35, fontSize: 26, padding: 11, color: '#26c6da' }}>
									<UncontrolledTooltip placement="top" target="TooltipTop2">
									   View Compensation
									</UncontrolledTooltip>
								 </i>
								 :
								 <i></i>
								}
			                 </div>
			                </div>
			              </div>
			            </div>
			         </div>
	                 );
	                })
	               }
				   	<div className="col-sm-12 col-xl-6 col-md-6">
						<div className="ribbon-wrapper card">
							<div className="card-body text-center">
								<div className="ribbon ribbon-bookmark ribbon-secondary">
									Payslip
								</div>
								<div className="row text-center">
									<div className="col col-sm-12 col-md-12">
										<Autocomplete
											freeSolo
											options={payslipMonths}
											getOptionLabel={option => option.name}
											value={month_val}                      
											onChange={(event, newValue) => {
											setMonthVal(newValue);
											}}            
											renderInput={params => (
											<TextField {...params} label="Month" margin="dense" variant="outlined" helperText="Please select payslip month" fullWidth />
											)}
										/>
									</div>
									<div className="col col-sm-12 col-md-12">
										<Autocomplete
											freeSolo
											options={payslipYears}
											getOptionLabel={option => option.name}
											value={year_val}                      
											onChange={(event, newValue) => {
											setYearVal(newValue);
											}}            
											renderInput={params => (
											<TextField {...params} label="Year" margin="dense" variant="outlined" helperText="Please select year" fullWidth />
											)}
										/>
									</div>
									<div className="col col-sm-12 col-md-12 m-t-15">
										<button className="btn btn-secondary" onClick={() => printPayslipCheck()}>
											Download Payslip
										</button>
									</div>
								</div>
								
							</div>
						</div>
					</div>
                 </div>
               </div>
              </div>
            </div>
            :
            <div>
                <h3 className="text-danger">Form is yet to be created</h3>
            </div>
            }
		</Fragment>
	);
}

export default withRouter(EmployeeMaster2);