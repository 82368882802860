import axios from 'axios';

let baseURL = 'http://localhost:9004/api';
if(process.env.NODE_ENV !== "development") {
  baseURL = "/api";
}
const instance = axios.create({
   withCredentials: true
 });

const makeRequest = (type, url, data) => {
  return instance[type](baseURL+url, data)
}

/* Users */
export const signinUser2 = (username, password) => {
  return makeRequest('post', '/login', {username, password})
  .then(resp => {
    if(resp.data){
      localStorage.setItem("s_token", resp.data.user.token);
      console.log("token", resp);
      localStorage.setItem("m_users", JSON.stringify(resp.data.user));
      return resp.data;
    }
  })
}
export const getUsers = () => {
  return makeRequest('get', '/users');
}
export const getAllUser = (group_id) => {
  return makeRequest('get', `/users/${group_id}`);
}
export const fetchUser = (userId) => {
  return makeRequest('get', `/edit_user/${userId}`);
}

export const postUsers = (data) => {
  return makeRequest('post', '/users', data);
}
export const editUsers = (data) => {
  return makeRequest('put', '/users', data);
}
export const deleteUsers = (data) => {
  return makeRequest('delete', '/users', {data: data});
}

/* Forgot Password  */

export const sendEmaillink = (data) => {
return makeRequest('post', '/forgotpassword', data);
}

export const sendresettoken = (data) => {
return makeRequest('post', '/resetpassword', data);
}

export const updatepaswordviaemail = (data) => {
  return makeRequest('post', '/updatepasswordviaemail', data)
}

/* Manager */
export const getManagers = (group_id) => {
  return makeRequest('get', `/managers/${group_id}`);
}
export const postManagers = (data) => {
  return makeRequest('post', '/managers', data);
}
export const editManagers = (data) => {
  return makeRequest('put', '/managers', data);
}
export const deleteManagers = (data) => {
  return makeRequest('delete', '/managers', {data: data});
}

/* Main Group */
export const getGroup = () => {
  return makeRequest('get', '/group');
}
export const postGroup = (data) => {
  return makeRequest('post', '/group', data);
}
export const editGroup = (data) => {
  return makeRequest('put', '/group', data);
}
export const deleteGroup = (data) => {
  return makeRequest('delete', '/group', {data: data});
}

/* Vendor Form */
export const getVendorForm = (group_id) => {
  return makeRequest('get', `/vendorform/${group_id}`);
}
export const postVendorForm = (data) => {
  return makeRequest('post', '/vendorform', data);
}
export const editVendorForm = (data) => {
  return makeRequest('put', '/vendorform', data);
}
export const approveVendors = (data) => {
  return makeRequest('put', '/vendorform2', data);
}
export const deleteVendorForm = (data) => {
  return makeRequest('delete', '/vendorform', {data: data});
}
export const aadharUpload = (data) => {
  return makeRequest('post', '/image_upload', data);
}
export const panUpload = (data) => {
  return makeRequest('post', '/pan_upload', data);
}
export const bankUpload = (data) => {
  return makeRequest('post', '/bank_upload', data);
}
export const isoUpload = (data) => {
  return makeRequest('post', '/iso_upload', data);
}
export const comUpload = (data) => {
  return makeRequest('post', '/com_upload', data);
}
export const esiUpload = (data) => {
  return makeRequest('post', '/esi_upload', data);
}
export const gstUpload = (data) => {
  return makeRequest('post', '/gst_upload', data);
}
export const pfUpload = (data) => {
  return makeRequest('post', '/pf_upload', data);
}
export const sendRejectVendorEmail = (data) => {
  return makeRequest('post', '/sendRejectVendorEmail', data);
}
export const sendBlockVendorEmail = (data) => {
  return makeRequest('post', '/sendBlockVendorEmail', data);
}
export const sendInviteVendor = (data) => {
  return makeRequest('post', '/sendInviteVendor', data);
} 
export const sendVendorLoginDetails = (data) => {
  return makeRequest('post', '/sendVendorLoginDetails', data);
}
export const sendPaymentEmail = (data) => {
  return makeRequest('post', '/sendPaymentEmail', data);
} 
export const sendClientEmail = (data) => {
  return makeRequest('post', '/sendClientEmail', data);
}

/* Vendor Place Form */
export const getVendorPlaceForm = (group_id) => {
  return makeRequest('get', `/vendorplaceform/${group_id}`);
}

/* Vendor Props Form */
export const getVendorPropForm = (group_id) => {
  return makeRequest('get', `/vendorpropform/${group_id}`);
}

export const filterVendorForms = (group_id) => {
  return makeRequest('get', `/filtervendor/${group_id}`);
}

/* Vendor Customer Form */
export const getVendorCustForm = (group_id) => {
  return makeRequest('get', `/vendorcustform/${group_id}`);
}

/* Vendor Machine Forms */
export const getVendorMachForm = (group_id) => {
  return makeRequest('get', `/vendormachform/${group_id}`);
}

/* Vendor Instruments Forms */
export const getVendorInstForm = (group_id) => {
  return makeRequest('get', `/vendorinstform/${group_id}`);
}

/* Downloads for Vendor Forms */
export const downloadReview = (data) => {
  return makeRequest('post', '/review_download', data);
}

export const downloadApprove = (data) => {
  return makeRequest('post', '/approve_download', data);
}

export const downloadReject = (data) => {
  return makeRequest('post', '/reject_download', data);
}

export const downloadBlock = (data) => {
  return makeRequest('post', '/block_download', data);
}

/* Send Email */
export const sendManagerEmail = (data) => {
  return makeRequest('post', '/sendManagerEmail', data);
}

export const sendVendorEmail = (data) => {
  return makeRequest('post', '/sendVendorEmail', data);
}

export const sendRTSEmail = (data) => {
  return makeRequest('post', '/sendRTSEmail', data);
} 

export const sendUserEmail = (data) => {
  return makeRequest('post', '/sendUserEmail', data);
}

export const sendLeaveEmail = (data) => {
  return makeRequest('post', '/sendLeaveEmail', data);
}

/* Item L1 */
export const getItemLone = (group_id) => {
  return makeRequest('get', `/itemlone/${group_id}`);
}
export const postItemLone = (data) => {
  return makeRequest('post', '/itemlone', data);
}
export const editItemLone = (data) => {
  return makeRequest('put', '/itemlone', data);
}
export const deleteItemLone = (data) => {
  return makeRequest('delete', '/itemlone', {data: data});
}

/* Item L2 */
export const getItemLtwo = (group_id) => {
  return makeRequest('get', `/itemltwo/${group_id}`);
}
export const postItemLtwo = (data) => {
  return makeRequest('post', '/itemltwo', data);
}
export const editItemLtwo = (data) => {
  return makeRequest('put', '/itemltwo', data);
}
export const deleteItemLtwo = (data) => {
  return makeRequest('delete', '/itemltwo', {data: data});
}

/* Item L3 */
export const getItemLthree = (group_id) => {
  return makeRequest('get', `/itemlthree/${group_id}`);
}
export const postItemLthree = (data) => {
  return makeRequest('post', '/itemlthree', data);
}
export const editItemLthree = (data) => {
  return makeRequest('put', '/itemlthree', data);
}
export const deleteItemLthree = (data) => {
  return makeRequest('delete', '/itemlthree', {data: data});
}

/* Item L4 */
export const getItemLfour = (group_id) => {
  return makeRequest('get', `/itemlfour/${group_id}`);
}
export const postItemLfour = (data) => {
  return makeRequest('post', '/itemlfour', data);
}
export const editItemLfour = (data) => {
  return makeRequest('put', '/itemlfour', data);
}
export const deleteItemLfour = (data) => {
  return makeRequest('delete', '/itemlfour', {data: data});
}

/* Item L5 */
export const getItemLfive = (group_id) => {
  return makeRequest('get', `/itemlfive/${group_id}`);
}
export const postItemLfive = (data) => {
  return makeRequest('post', '/itemlfive', data);
}
export const editItemLfive = (data) => {
  return makeRequest('put', '/itemlfive', data);
}
export const deleteItemLfive = (data) => {
  return makeRequest('delete', '/itemlfive', {data: data});
}

/* Clients */
export const getClients = (group_id) => {
  return makeRequest('get', `/clients/${group_id}`);
}
export const postClients = (data) => {
  return makeRequest('post', '/clients', data);
}
export const editClients = (data) => {
  return makeRequest('put', '/clients', data);
}
export const deleteClients = (data) => {
  return makeRequest('delete', '/clients', {data: data});
}
export const approveClients = (data) => {
  return makeRequest('put', '/approve_client', data);
}
export const msaUpload = (data) => {
  return makeRequest('post', '/msa_upload', data);
}
export const othermsaUpload = (data) => {
  return makeRequest('post', '/othermsa_upload', data);
}

/* Projects */
export const getProjects = (group_id) => {
  return makeRequest('get', `/projects/${group_id}`);
}
export const postProjects = (data) => {
  return makeRequest('post', '/projects2', data);
}
export const editProjects = (data) => {
  return makeRequest('put', '/projects', data);
}
export const deleteProjects = (data) => {
  return makeRequest('delete', '/projects', {data: data});
}
export const approveProjects = (data) => {
  return makeRequest('put', '/approve_project', data);
}

/* CostSheet */
export const getCostSheet = (group_id) => {
  return makeRequest('get', `/cost_sheets/${group_id}`);
}
export const postCostSheet = (data) => {
  return makeRequest('post', '/cost_sheets', data);
} 
export const postCostSheet2 = (data) => {
  return makeRequest('post', '/cost_sheets2', data);
}
export const editCostSheet = (data) => {
  return makeRequest('put', '/cost_sheets', data);
}
export const deleteCostSheet = (data) => {
  return makeRequest('delete', '/cost_sheets', {data: data});
}

export const BulkUploadCostSheet = (data) => {
  return makeRequest('post', '/bulkcostsheet', data);
}
export const runScript = () => {
  return makeRequest('get', '/runscript/');
}
export const BulkUploadBOM = (data) => {
  return makeRequest('post', '/bulkupload_bom', data);
}
export const runBOMScript = () => {
  return makeRequest('get', '/run_bomscript/');
}
export const pushCostSheet = () => {
  return makeRequest('get', '/push_costsheet');
}

/* CostSheet */
export const getMasterBOM = (group_id) => {
  return makeRequest('get', `/master_bom/${group_id}`);
}
export const postMasterBOM = (data) => {
  return makeRequest('post', '/master_bom', data);
}
export const editMasterBOM = (data) => {
  return makeRequest('put', '/master_bom', data);
}
export const deleteMasterBOM = (data) => {
  return makeRequest('delete', '/master_bom', {data: data});
}

/* Boms */ 
export const getBoms = (group_id) => {
  return makeRequest('get', `/boms/${group_id}`);
}
export const postBoms = (data) => {
  return makeRequest('post', '/boms', data);
}
export const editBoms = (data) => {
  return makeRequest('put', '/boms', data);
}
export const deleteBoms = (data) => {
  return makeRequest('delete', '/boms', {data: data});
}
export const BulkUploadIBOM = (data) => {
  return makeRequest('post', '/bulkupload_ibom', data);
}
export const runIBOMScript = (data) => {
  return makeRequest('post', '/run_boms', data);
}

/* Bom Items */ 
export const getBomItems = (group_id) => {
  return makeRequest('get', `/bom_items/${group_id}`);
}
export const postBomItems = (data) => {
  return makeRequest('post', '/bom_items', data);
}
export const editBomItems = (data) => {
  return makeRequest('put', '/bom_items', data);
}
export const deleteBomItems = (data) => {
  return makeRequest('delete', '/bom_items', {data: data});
}

/* Indent */
export const getIndent = (group_id) => {
  return makeRequest('get', `/indent/${group_id}`);
}
export const postIndent = (data) => {
  return makeRequest('post', '/indent', data);
}
export const createIndent = (data) => {
  return makeRequest('post', '/indent2', data);
}
export const editIndent = (data) => {
  return makeRequest('put', '/indent', data);
}
export const updateIndent = (data) => {
  return makeRequest('put', '/indent2', data);
}
export const deleteIndent = (data) => {
  return makeRequest('delete', '/indent', {data: data});
}
export const indentUpload = (data) => {
  return makeRequest('post', '/indent_upload', data);
}

/* Indent Items */
export const getIndentItems = (group_id) => {
  return makeRequest('get', `/indent_items/${group_id}`);
}
export const postIndentItems = (data) => {
  return makeRequest('post', '/indent_items', data);
}
export const editIndentItems = (data) => {
  return makeRequest('put', '/indent_items', data);
}
export const editIndentItemsPO = (data) => {
  return makeRequest('put', '/indent_items2', data);
}
export const deleteIndentItems = (data) => {
  return makeRequest('delete', '/indent_items', {data: data});
}

/* Purchase Order */
export const getPorders = (group_id) => {
  return makeRequest('get', `/porders/${group_id}`);
}
export const postPorders = (data) => {
  return makeRequest('post', '/porders', data);
}
export const editPorders = (data) => {
  return makeRequest('put', '/porders', data);
}
export const deletePorders = (data) => {
  return makeRequest('delete', '/porders', {data: data});
}

/* Purchase Order Items */
export const getPOItems = (group_id) => {
  return makeRequest('get', `/poitems/${group_id}`);
}
export const postPOItems = (data) => {
  return makeRequest('post', '/poitems', data);
}
export const editPOItems = (data) => {
  return makeRequest('put', '/poitems', data);
}
export const deletePOItems = (data) => {
  return makeRequest('delete', '/poitems', {data: data});
}

/* Checkout */
export const getAddCart = (group_id) => {
  return makeRequest('get', `/carts/${group_id}`);
}
export const postAddCart = (data) => {
  return makeRequest('post', '/carts', data);
}
export const postAddCart2 = (data) => {
  return makeRequest('post', '/carts2', data);
}
export const editCart = (data) => {
  return makeRequest('put', '/carts', data);
}
export const deleteCart = (data) => {
  return makeRequest('delete', '/carts', {data: data});
}
export const drawingUpload = (data) => {
  return makeRequest('post', '/drawing_upload', data);
}

/* Security Logs */
export const getLogs = (group_id) => {
  return makeRequest('get', `/security/${group_id}`);
}
export const postLogs = (data) => {
  return makeRequest('post', '/security', data);
}
export const editLogs = (data) => {
  return makeRequest('put', '/security', data);
}
export const deleteLogs = (data) => {
  return makeRequest('delete', '/security', {data: data});
}

/* GRIN */
export const getGrin = (group_id) => {
  return makeRequest('get', `/grin/${group_id}`);
}
export const postGrin = (data) => {
  return makeRequest('post', '/grin', data);
}
export const editGrin = (data) => {
  return makeRequest('put', '/grin', data);
}
export const deleteGrin = (data) => {
  return makeRequest('delete', '/grin', {data: data});
}
export const doUpload = (data) => {
  return makeRequest('post', '/do_upload', data);
} 

/* GRIN Items */
export const getGrinItems = (group_id) => {
  return makeRequest('get', `/grinitems/${group_id}`);
}
export const postGrinItems = (data) => {
  return makeRequest('post', '/grinitems', data);
}
export const editGrinItems = (data) => {
  return makeRequest('put', '/grinitems', data);
}
export const deleteGrinItems = (data) => {
  return makeRequest('delete', '/grinitems', {data: data});
}
export const downloadPendingQC = (data) => {
  return makeRequest('post', '/pendingqc_download', data);
}
export const downloadGRNCompleted = (data) => {
  return makeRequest('post', '/grncompleted_download', data);
}

/* QC Rejected Items */
export const getQcReject = (group_id) => {
  return makeRequest('get', `/qcreject/${group_id}`);
}
export const postQcReject = (data) => {
  return makeRequest('post', '/qcreject', data);
}
export const editQcReject = (data) => {
  return makeRequest('put', '/qcreject', data);
}
export const deleteQcReject = (data) => {
  return makeRequest('delete', '/qcreject', {data: data});
}

/* GR Logs */
export const getGrLogs = (group_id) => {
  return makeRequest('get', `/grlogs/${group_id}`);
}
export const postGrLogs = (data) => {
  return makeRequest('post', '/grlogs', data);
}
export const editGrLogs = (data) => {
  return makeRequest('put', '/grlogs', data);
}
export const deleteGrLogs = (data) => {
  return makeRequest('delete', '/grlogs', {data: data});
}

/* Invoice Processing */
export const uploadInvoice = (data) => {
  return makeRequest('post', '/upload_invoice', data);
}
export const runInvoiceScript = (data) => {
  return makeRequest('post', '/run_invoice', data);
}
export const getInvoice = (group_id) => {
  return makeRequest('get', `/invoice/${group_id}`);
}
export const editInvoice = (data) => {
  return makeRequest('put', '/invoice', data);
}
export const postInvoice = (data) => {
  return makeRequest('post', '/invoice', data);
}
export const deleteInvoice = (data) => {
  return makeRequest('delete', '/invoice', {data: data});
}

/* Invoice Items */
export const getInvoiceItems = (group_id) => {
  return makeRequest('get', `/invoice_items/${group_id}`);
}
export const postInvoiceItems = (data) => {
  return makeRequest('post', '/invoice_items', data);
}
export const postInvoiceItems2 = (data) => {
  return makeRequest('post', '/invoice_items2', data);
}
export const editInvoiceItems = (data) => {
  return makeRequest('put', '/invoice_items', data);
}
export const deleteInvoiceItems = (data) => {
  return makeRequest('delete', '/invoice_items', {data: data});
}

/* Invoice Temporary */
export const getInvoiceTemp = (group_id) => {
  return makeRequest('get', `/invoice_temp/${group_id}`);
}
export const postInvoiceTemp = (data) => {
  return makeRequest('post', '/invoice_temp', data);
}
export const editInvoiceTemp = (data) => {
  return makeRequest('put', '/invoice_temp', data);
}
export const deleteInvoiceTemp = (data) => {
  return makeRequest('delete', '/invoice_temp', {data: data});
}

/* Indian Invoice Processing */
export const uploadIndInvoice = (data) => {
  return makeRequest('post', '/upload_in_invoice', data);
}
export const runInIndInvoiceScript = (data) => {
  return makeRequest('post', '/run_in_invoice', data);
}
export const getIndInvoice = (group_id) => {
  return makeRequest('get', `/in_invoice/${group_id}`);
}
export const editIndInvoice = (data) => {
  return makeRequest('put', '/in_invoice', data);
}
export const updateIndInvoice = (data) => {
  return makeRequest('put', '/in_invoice2', data);
}
export const updateIndInvPayment = (data) => {
  return makeRequest('put', '/in_invoice3', data);
}
export const postIndInvoice = (data) => {
  return makeRequest('post', '/in_invoice', data);
}
export const deleteIndInvoice = (data) => {
  return makeRequest('delete', '/in_invoice', {data: data});
}

/* Indian Invoice Items */
export const getIndInvoiceItems = (group_id) => {
  return makeRequest('get', `/in_invoice_items/${group_id}`);
}
export const postIndInvoiceItems = (data) => {
  return makeRequest('post', '/in_invoice_items', data);
}
export const postIndInvoiceItems2 = (data) => {
  return makeRequest('post', '/in_invoice_items2', data);
}
export const editIndInvoiceItems = (data) => {
  return makeRequest('put', '/in_invoice_items', data);
}
export const editIndInvoiceItems2 = (data) => {
  return makeRequest('put', '/in_invoice_items3', data);
}
export const deleteIndInvoiceItems = (data) => {
  return makeRequest('delete', '/in_invoice_items', {data: data});
}
export const updateInvItems = (data) => {
  return makeRequest('put', '/in_invoice_items2', data);
}

/* Indian Invoice Temporary */
export const getIndInvoiceTemp = (group_id) => {
  return makeRequest('get', `/in_invoice_temp/${group_id}`);
}
export const postIndInvoiceTemp = (data) => {
  return makeRequest('post', '/in_invoice_temp', data);
}
export const editIndInvoiceTemp = (data) => {
  return makeRequest('put', '/in_invoice_temp', data);
}
export const deleteIndInvoiceTemp = (data) => {
  return makeRequest('delete', '/in_invoice_temp', {data: data});
}

/* Bank Statement  */
export const uploadBankStmt = (data) => {
  return makeRequest('post', '/upload_bank_stmt', data);
}
export const runBankScript = (data) => {
  return makeRequest('post', '/run_bank_script', data);
}
export const getBankStmt = (group_id) => {
  return makeRequest('get', `/bank_stmt/${group_id}`);
}
export const editBankStmt = (data) => {
  return makeRequest('put', '/bank_stmt', data);
}
export const postBankStmt = (data) => {
  return makeRequest('post', '/bank_stmt', data);
}
export const deleteBankStmt = (data) => {
  return makeRequest('delete', '/bank_stmt', {data: data});
}
export const downloadBankStatement = (data) => {
  return makeRequest('post', '/bank_download', data);
}

/* Credit card Statement  */
export const uploadCreditStmt = (data) => {
  return makeRequest('post', '/upload_credit_stmt', data);
}
export const runCreditScript = (data) => {
  return makeRequest('post', '/run_credit_script', data);
}
export const getCreditStmt = (group_id) => {
  return makeRequest('get', `/credit_stmt/${group_id}`);
}
export const editCreditStmt = (data) => {
  return makeRequest('put', '/credit_stmt', data);
}
export const postCreditStmt = (data) => {
  return makeRequest('post', '/credit_stmt', data);
}
export const deleteCreditStmt = (data) => {
  return makeRequest('delete', '/credit_stmt', {data: data});
}
export const downloadCreditStatement = (data) => {
  return makeRequest('post', '/credit_download', data);
}

/* Temp Bank Statement */
export const getTempBank = (group_id) => {
  return makeRequest('get', `/temp_bank/${group_id}`);
}
export const editTempBank = (data) => {
  return makeRequest('put', '/temp_bank', data);
}
export const postTempBank = (data) => {
  return makeRequest('post', '/temp_bank', data);
}
export const deleteTempBank = (data) => {
  return makeRequest('delete', '/temp_bank', {data: data});
}

/* Temp Credit Statement */
export const getTempCredit = (group_id) => {
  return makeRequest('get', `/temp_credit/${group_id}`);
}
export const editTempCredit = (data) => {
  return makeRequest('put', '/temp_credit', data);
}
export const postTempCredit = (data) => {
  return makeRequest('post', '/temp_credit', data);
}
export const deleteTempCredit = (data) => {
  return makeRequest('delete', '/temp_credit', {data: data});
}

/* Tags for Bank & Credit Statement */
export const getTags = (group_id) => {
  return makeRequest('get', `/tags/${group_id}`);
}
export const editTags = (data) => {
  return makeRequest('put', '/tags', data);
}
export const postTags = (data) => {
  return makeRequest('post', '/tags', data);
}
export const deleteTags = (data) => {
  return makeRequest('delete', '/tags', {data: data});
}

/* TDS */
export const getTds = (group_id) => {
  return makeRequest('get', `/tds/${group_id}`);
}
export const editTds = (data) => {
  return makeRequest('put', '/tds', data);
}
export const postTds = (data) => {
  return makeRequest('post', '/tds', data);
}
export const deleteTds = (data) => {
  return makeRequest('delete', '/tds', {data: data});
}

/* Payments */
export const getPayments = (group_id) => {
  return makeRequest('get', `/payments/${group_id}`);
}
export const editPayments = (data) => {
  return makeRequest('put', '/payments', data);
}
export const postPayments = (data) => {
  return makeRequest('post', '/payments', data);
}
export const deletePayments = (data) => {
  return makeRequest('delete', '/payments', {data: data});
}
export const getCashfreeBalance = () => {
  return makeRequest('get', '/payments2');
}
export const cashDocUpload = (data) => {
  return makeRequest('post', '/cash_upload', data);
} 
export const postCashPayments = (data) => {
  return makeRequest('post', '/cash_payments', data);
} 
export const postF2sms = (data) => {
  return makeRequest('post', '/fast2sms', data);
}

/* Inventory Master */
export const getMasterInventory = (group_id) => {
  return makeRequest('get', `/inventory_master/${group_id}`);
}
export const editMasterInventory = (data) => {
  return makeRequest('put', '/inventory_master', data);
}
export const postMasterInventory = (data) => {
  return makeRequest('post', '/inventory_master', data);
}
export const deleteMasterInventory = (data) => {
  return makeRequest('delete', '/inventory_master', {data: data});
}
export const downloadMasterInventory = (data) => {
  return makeRequest('post', '/inventory_master_download', data);
}
export const filterMasterInventory = (group_id) => {
  return makeRequest('get', `/filter_master_inventory/${group_id}`);
}

/* Inventory Transaction */
export const getTransInventory = (group_id) => {
  return makeRequest('get', `/inventory_trans/${group_id}`);
}
export const editTransInventory = (data) => {
  return makeRequest('put', '/inventory_trans', data);
}
export const postTransInventory = (data) => {
  return makeRequest('post', '/inventory_trans', data);
}
export const postTransInventory2 = (data) => {
  return makeRequest('post', '/inventory_trans2', data);
}
export const postTransInventory3 = (data) => {
  return makeRequest('post', '/inventory_trans3', data);
}
export const deleteTransInventory = (data) => {
  return makeRequest('delete', '/inventory_trans', {data: data});
}
export const getIssuanceDownload = (data) => {
  return makeRequest('post', '/issuance_download', data);
}
export const getTransactionDownload = (data) => {
  return makeRequest('post', '/transaction_download', data);
}

/* Inventory Cart */
export const getCartsInventory = (group_id) => {
  return makeRequest('get', `/inventory_carts/${group_id}`);
}
export const editCartsInventory = (data) => {
  return makeRequest('put', '/inventory_carts', data);
}
export const postCartsInventory = (data) => {
  return makeRequest('post', '/inventory_carts', data);
}
export const deleteCartsInventory = (data) => {
  return makeRequest('delete', '/inventory_carts', {data: data});
}

/* Material Rejections */
export const getMaterialRejects = (group_id) => {
  return makeRequest('get', `/mreject/${group_id}`);
}
export const editMaterialRejects = (data) => {
  return makeRequest('put', '/mreject', data);
}
export const postMaterialRejects = (data) => {
  return makeRequest('post', '/mreject', data);
}
export const deleteMaterialRejects = (data) => {
  return makeRequest('delete', '/mreject', {data: data});
}

/* Send WhatsApp */
export const postWhatsAppInvoice = (data) => {
  return makeRequest('post', '/wappinvoice', data);
} 
export const postRTSWhatsApp = (data) => {
  return makeRequest('post', '/wappinvoice2', data);
} 
export const sendWappInvoice = (data) => {
  return makeRequest('post', '/wappinvoice3', data);
}

/* Raised Invoices */
export const getRaisedInvoices = (group_id) => {
  return makeRequest('get', `/raise_invoice/${group_id}`);
}
export const editRaisedInvoices = (data) => {
  return makeRequest('put', '/raise_invoice', data);
} 
export const editRaisedInvStatus = (data) => {
  return makeRequest('put', '/raise_invoice2', data);
}
export const editRaisedInvoices2 = (data) => {
  return makeRequest('put', '/raise_invoice3', data);
}
export const editRaisedInvCredit = (data) => {
  return makeRequest('put', '/raise_invoice4', data);
}
export const postRaisedInvoices = (data) => {
  return makeRequest('post', '/raise_invoice', data);
} 
export const checkOrderStatus = (data) => {
  return makeRequest('post', '/order_status', data);
}
export const postCreditNote = (data) => {
  return makeRequest('post', '/raise_credit', data);
}
export const deleteRaisedInvoices = (data) => {
  return makeRequest('delete', '/raise_invoice', {data: data});
}

/* Raised Invoice Items */
export const getRaisedInvItems = (group_id) => {
  return makeRequest('get', `/raise_inv_items/${group_id}`);
}
export const editRaisedInvItems = (data) => {
  return makeRequest('put', '/raise_inv_items', data);
}
export const postRaisedInvItems = (data) => {
  return makeRequest('post', '/raise_inv_items', data);
}
export const deleteRaisedInvItems = (data) => {
  return makeRequest('delete', '/raise_inv_items', {data: data});
}

/* Settings  */
export const getSettings = (group_id) => {
  return makeRequest('get', `/settings/${group_id}`);
}
export const editSettings = (data) => {
  return makeRequest('put', '/settings', data);
}
export const postSettings = (data) => {
  return makeRequest('post', '/settings', data);
}
export const deleteSettings = (data) => {
  return makeRequest('delete', '/settings', {data: data});
}

/* Bill Payments  */
export const getBillPayments = (group_id) => {
  return makeRequest('get', `/bill_payments/${group_id}`);
}
export const editBillPayments = (data) => {
  return makeRequest('put', '/bill_payments', data);
}
export const postBillPayments = (data) => {
  return makeRequest('post', '/bill_payments', data);
}
export const deleteBillPayments = (data) => {
  return makeRequest('delete', '/bill_payments', {data: data});
}

/* Warehouse  */
export const getWarehouse = (group_id) => {
  return makeRequest('get', `/warehouse/${group_id}`);
}
export const editWarehouse = (data) => {
  return makeRequest('put', '/warehouse', data);
}
export const postWarehouse = (data) => {
  return makeRequest('post', '/warehouse', data);
}
export const deleteWarehouse = (data) => {
  return makeRequest('delete', '/warehouse', {data: data});
}

/* Employee Master  */
export const getEmployee = (group_id) => {
  return makeRequest('get', `/employee/${group_id}`);
}
export const editEmployee = (data) => {
  return makeRequest('put', '/employee', data);
}
export const postEmployee = (data) => {
  return makeRequest('post', '/employee', data);
}
export const deleteEmployee = (data) => {
  return makeRequest('delete', '/employee', {data: data});
}
export const passportUpload = (data) => {
  return makeRequest('post', '/pass_upload', data);
}
export const experienceUpload = (data) => {
  return makeRequest('post', '/exp_upload', data);
}
export const relieveUpload = (data) => {
  return makeRequest('post', '/rel_upload', data);
}
export const tenthUpload = (data) => {
  return makeRequest('post', '/tenth_upload', data);
}
export const twelthUpload = (data) => {
  return makeRequest('post', '/twelth_upload', data);
}
export const degreeUpload = (data) => {
  return makeRequest('post', '/degree_upload', data);
}
export const contractUpload = (data) => {
  return makeRequest('post', '/contract_upload', data);
}

/* Department  */
export const getDepartment = (group_id) => {
  return makeRequest('get', `/department/${group_id}`);
}
export const editDepartment = (data) => {
  return makeRequest('put', '/department', data);
}
export const postDepartment = (data) => {
  return makeRequest('post', '/department', data);
}
export const deleteDepartment = (data) => {
  return makeRequest('delete', '/department', {data: data});
}

/* Designation  */
export const getDesignation = (group_id) => {
  return makeRequest('get', `/designation/${group_id}`);
}
export const editDesignation = (data) => {
  return makeRequest('put', '/designation', data);
}
export const postDesignation = (data) => {
  return makeRequest('post', '/designation', data);
}
export const deleteDesignation = (data) => {
  return makeRequest('delete', '/designation', {data: data});
}

/* Compensation  */
export const getCompensation = (group_id) => {
  return makeRequest('get', `/compensation/${group_id}`);
}
export const editCompensation = (data) => {
  return makeRequest('put', '/compensation', data);
}
export const editCompensation2 = (data) => {
  return makeRequest('put', '/compensation2', data);
}
export const postCompensation = (data) => {
  return makeRequest('post', '/compensation', data);
}
export const deleteCompensation = (data) => {
  return makeRequest('delete', '/compensation', {data: data});
}

/* Holidays  */
export const getHoliday = (group_id) => {
  return makeRequest('get', `/holiday/${group_id}`);
}
export const editHoliday = (data) => {
  return makeRequest('put', '/holiday', data);
}
export const postHoliday = (data) => {
  return makeRequest('post', '/holiday', data);
}
export const deleteHoliday = (data) => {
  return makeRequest('delete', '/holiday', {data: data});
}

/* Book Leave  */
export const getBookLeave = (group_id) => {
  return makeRequest('get', `/book_leave/${group_id}`);
}
export const editBookLeave= (data) => {
  return makeRequest('put', '/book_leave', data);
}
export const postBookLeave = (data) => {
  return makeRequest('post', '/book_leave', data);
}
export const deleteBookLeave = (data) => {
  return makeRequest('delete', '/book_leave', {data: data});
}

/* Compensation Category  */
export const getComCategory = (group_id) => {
  return makeRequest('get', `/com_category/${group_id}`);
}
export const editComCategory = (data) => {
  return makeRequest('put', '/com_category', data);
}
export const postComCategory = (data) => {
  return makeRequest('post', '/com_category', data);
}
export const deleteComCategory = (data) => {
  return makeRequest('delete', '/com_category', {data: data});
}

/* Compensation Component  */
export const getComComponents = (group_id) => {
  return makeRequest('get', `/com_component/${group_id}`);
}
export const editComComponents = (data) => {
  return makeRequest('put', '/com_component', data);
}
export const postComComponents = (data) => {
  return makeRequest('post', '/com_component', data);
}
export const deleteComComponents = (data) => {
  return makeRequest('delete', '/com_component', {data: data});
}

/* Payroll */
export const getPayroll = (group_id) => {
  return makeRequest('get', `/payroll/${group_id}`);
}
export const editPayroll = (data) => {
  return makeRequest('put', '/payroll', data);
}
export const editPayroll2 = (data) => {
  return makeRequest('put', '/payroll2', data);
}
export const editPayroll3 = (data) => {
  return makeRequest('put', '/payroll3', data);
}
export const editPayroll4 = (data) => {
  return makeRequest('put', '/payroll4', data);
}
export const postPayroll = (data) => {
  return makeRequest('post', '/payroll', data);
}
export const deletePayroll = (data) => {
  return makeRequest('delete', '/payroll', {data: data});
}
export const downloadPayroll = (data) => {
  return makeRequest('post', '/payroll_download', data);
}

/* Stop Payroll */
export const getStopPayroll = (group_id) => {
  return makeRequest('get', `/stop_payroll/${group_id}`);
}
export const editStopPayroll = (data) => {
  return makeRequest('put', '/stop_payroll', data);
}
export const postStopPayroll = (data) => {
  return makeRequest('post', '/stop_payroll', data);
}
export const deleteStopPayroll = (data) => {
  return makeRequest('delete', '/stop_payroll', {data: data});
}

/* Payroll Addition */
export const getPayrollAdd = (group_id) => {
  return makeRequest('get', `/payroll_add/${group_id}`);
}
export const editPayrollAdd = (data) => {
  return makeRequest('put', '/payroll_add', data);
}
export const postPayrollAdd = (data) => {
  return makeRequest('post', '/payroll_add', data);
}
export const deletePayrollAdd = (data) => {
  return makeRequest('delete', '/payroll_add', {data: data});
} 
export const deleteAllPayrollAdd = (data) => {
  return makeRequest('delete', '/payroll_delete', {data: data});
}
export const payrollAddUpload = (data) => {
  return makeRequest('post', '/payroll_add_upload', data);
}
export const payrollDeductUpload = (data) => {
  return makeRequest('post', '/payroll_deduct_upload', data);
}

/* Employee Tax */
export const getEmployeeTax = (group_id) => {
  return makeRequest('get', `/emp_tax/${group_id}`);
}
export const editEmployeeTax = (data) => {
  return makeRequest('put', '/emp_tax', data);
}
export const postEmployeeTax = (data) => {
  return makeRequest('post', '/emp_tax', data);
}
export const deleteEmployeeTax = (data) => {
  return makeRequest('delete', '/emp_tax', {data: data});
}

/* Employee Tax Paid*/
export const getEmployeeTaxPaid = (group_id) => {
  return makeRequest('get', `/tax_paid/${group_id}`);
}
export const editEmployeeTaxPaid = (data) => {
  return makeRequest('put', '/tax_paid', data);
}
export const postEmployeeTaxPaid = (data) => {
  return makeRequest('post', '/tax_paid', data);
}
export const deleteEmployeeTaxPaid = (data) => {
  return makeRequest('delete', '/tax_paid', {data: data});
}

/* Timesheet Project */
export const getTsProject = (group_id) => {
  return makeRequest('get', `/ts_project/${group_id}`);
}
export const editTsProject = (data) => {
  return makeRequest('put', '/ts_project', data);
}
export const postTsProject = (data) => {
  return makeRequest('post', '/ts_project', data);
}
export const deleteTsProject = (data) => {
  return makeRequest('delete', '/ts_project', {data: data});
}
export const getTsProjectType = (group_id) => {
  return makeRequest('get', '/ts_project2');
}
export const postTsProjectType = (data) => {
  return makeRequest('post', '/ts_project2', data);
}

/* Timesheet Project Addition */
export const getTsAdd = (group_id) => {
  return makeRequest('get', `/ts_add/${group_id}`);
}
export const editTsAdd = (data) => {
  return makeRequest('put', '/ts_add', data);
}
export const postTsAdd = (data) => {
  return makeRequest('post', '/ts_add', data);
}
export const deleteTsAdd = (data) => {
  return makeRequest('delete', '/ts_add', {data: data});
}