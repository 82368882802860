import React, {Fragment, useState, useEffect} from 'react'
import { TablePagination } from '@material-ui/core';
import { getRaisedInvoices, getBillPayments, getProjects, getAllUser } from '../../../../actions/asyncActions';

const Overdue = () => {
   const user = JSON.parse(localStorage.getItem("m_users"));
   const group_id = user.group_id;

    const elementStyle ={
        border:'solid',
        borderRadius:'10px',
        position:'relative',
        left:'1vh',
        height:'6vh',
        width:'23vh',
        marginTop:'1vh',
        marginBottom:'1vh',
        textAlign: 'center'
    }

    const sortTypes = {
        up: {
            class: 'sort-up',
            fn: (a, b) => new Date(a.due_date) - new Date(b.due_date)
        },
        down: {
            class: 'sort-down',
            fn: (a, b) => new Date(b.due_date) - new Date(a.due_date)
        },
        default: {
            class: 'sort',
            fn: (a, b) => a
        }
    };

    const [currentSort, setCurrentSort] = useState('default')
    console.log("currentSort", currentSort)

    const onSortChange = () => {
		let nextSort;

		if (currentSort === 'down') nextSort = 'up';
		else if (currentSort === 'up') nextSort = 'default';
		else if (currentSort === 'default') nextSort = 'down';

        setCurrentSort(nextSort)
	};

   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(5);
   
   const handleChangePage = (event, newPage) => {
	 setPage(newPage);
   };

   const handleChangeRowsPerPage = event => {
	 setRowsPerPage(parseInt(event.target.value, 10));
	 setPage(0);
   };

   const [raisedinvoices, setRaisedInvoices] = useState([]);
   const filterInvoices = raisedinvoices.filter(x=>x.status_type === 'Approved');

   const [projects, setProjects] = useState([]);
   const [users, setUsers] = useState([]);
   const [billpayments, setBillPayments] = useState([]);

   useEffect( () => {
    getRaisedInvoices(group_id)
    .then(resp => setRaisedInvoices(resp.data))
   }, []);

   useEffect( () => {
    getProjects(group_id)
    .then(resp => setProjects(resp.data))
   }, []);

   useEffect( () => {
    getAllUser(group_id)
    .then(resp => setUsers(resp.data))
   }, []);

   useEffect( () => {
    getBillPayments(group_id)
    .then(resp => setBillPayments(resp.data))
   }, []);

   const [search, setSearch] = useState('');
   const [filteredInvoices, setFilteredInvoices] = useState([]);
  
    // useEffect(() => {
  	//   setFilteredInvoices(
    //     raisedinvoices.filter(n => {
    //         return n.client_name.toLowerCase().includes(search.toLowerCase()) ||
    //             n.sales_incharge.toLowerCase().includes(search.toLowerCase());
  	//    })
  	//   )
    // }, [search, raisedinvoices])

    const filterItems = (p) => {
        if(search) {
         return p.invoice_no.toLowerCase().includes(search.toLowerCase()) || 
         p.client_name.toLowerCase().includes(search.toLowerCase()) || 
          p.sales_incharge.toLowerCase().includes(search.toLowerCase());
       }
       return true;
    }

    return(
     <Fragment>
        <div className="container-fluid">
         <div className="row">
            <div className="col-xl-12">
              <input type="text" placeholder="Search Invoice" style={elementStyle} value={search}
	            onChange={e => setSearch(e.target.value)} />
            </div>
            <div className="col-xl-12">
			    <div className="table-responsive">
					<table className="table table-sm table-bordered table-hover">
						<thead className="table-primary">
							<tr>
								<th scope="col">Inv #</th>
								<th scope="col">DueDate</th>
								<th scope="col">Client</th>
								<th scope="col">SalesIncharge</th>
								<th scope="col">Amount</th>
								<th scope="col">Paid</th>
								<th scope="col">Balance</th>
								<th scope="col">
                                    Overdue &nbsp;
                                    <button onClick={onSortChange} style={{fontSize:16}}>
									 <i className={`fa fa-${sortTypes[currentSort].class}`} />
								    </button>
                                </th>
							</tr>
						</thead>
                        <tbody>
						{[...filterInvoices].sort(sortTypes[currentSort].fn).filter(filterItems).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((n, i)=> {
                            const findBill = billpayments.filter(x=>x.invoice_id === n.id);
                            const paidAmount = findBill.reduce((a,b) => a + parseFloat(b.paid_amount), 0);
                            const balance = parseFloat(n.total_amount) - paidAmount;
                            const currentDate = new Date();
                            const dueDate = new Date(n.due_date)
                            var difference_In_Time = currentDate.getTime() - dueDate.getTime();
                            var difference_In_Days = Math.round(difference_In_Time / (1000 * 3600 * 24));

                            return (
							   <tr>
								<td>{n.invoice_no}</td>
                                <td>
                                {new Date(n.due_date).toLocaleString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                </td>
								<td>{n.client_name}</td>
								<td>{n.sales_incharge}</td>
								<td>{n.total_amount}</td>
                                <td>{paidAmount}</td>
                                <td>{balance}</td>
                                <td>{difference_In_Days}</td>
							   </tr>
							);
					      })
						}
						</tbody>
                    </table>
                </div>
            </div>
            <div className="col-sm-12">
                <div className="card">
                    <div className="card-block row">
                        <div className="col-sm-6 col-lg-6 col-xl-6 m-t-15">
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xl-6">
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredInvoices.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={handleChangePage}
                                onChangeRowsPerPage={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                </div>
            </div>
         </div>
        </div>
     </Fragment>
    );
}

export default Overdue;