import React, { Fragment } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { Upload, Briefcase, ThumbsUp, ThumbsDown  } from 'react-feather';
import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import InvoiceProcess from './InvoiceProcess';
import InvoiceReview from './InvoiceReview';
import InvoiceApproved from './InvoiceApproved';
import InvoiceRejected from './InvoiceRejected';

const InvoiceTab = () => {
        return (
            <Fragment>
                <Breadcrumb title="Invoicing" parent="Accounts" />
                <div className="container-fluid">
                    <div className="row theme-tab">
                        <Tabs className="col-sm-12">
                            <TabList className="tabs tab-title">
                                <Tab className="current">
                                    <Upload />Upload Invoice
                                </Tab>
                                <Tab>
                                    <Briefcase />Review
                                </Tab>
                                <Tab>
                                    <ThumbsUp  />Approved
                                </Tab>
                                <Tab>
                                    <ThumbsDown />Rejected
                                </Tab>
                            </TabList>
                            <div className="tab-content-cls">
                                <TabPanel>
                                    <InvoiceProcess />
                                </TabPanel>
                                <TabPanel>
                                    <InvoiceReview />
                                </TabPanel>
                                <TabPanel>
                                    <InvoiceApproved />
                                </TabPanel>
                                <TabPanel>
                                    <InvoiceRejected />
                                </TabPanel>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </Fragment>
        );
}

export default InvoiceTab;