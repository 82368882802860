import React, { Fragment, useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import {TextField, Backdrop , CircularProgress, TablePagination} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import {Autocomplete} from '@material-ui/lab';
import { getVendorForm, getPorders, uploadIndInvoice, getIndInvoiceTemp, postIndInvoiceTemp, getIndInvoice } from '../../actions/asyncActions';

const useStyles = makeStyles(theme => ({
  circular: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const InvoiceProcessing = () => {
 
 const classes = useStyles();
 const theme = useTheme();
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 var [isLoading, setIsLoading] = useState(false);
 
 const [vendorForm, setVendors] = useState([]);
 const approvedVendors = vendorForm.filter(x=>x.status === 'Approved');
 const [vendor_val, setVendorVal] = useState(null);
 const selectedVendor = vendor_val && vendor_val.id;
 const selectedType = vendor_val && vendor_val.type;
 const aaa = vendor_val && vendor_val.supname;
 let bbb = aaa;
 var selectedVendorName = (bbb || '').toUpperCase();
 const [porders, setPOrders] = useState([]);
 const [porder_val, setPorderVal] = useState(null);
 const selectedPO = porder_val && porder_val.id;
 const project_id = porder_val && porder_val.project_id;
 const filterPo = porders.filter(x=>x.vendor_id === selectedVendor);
 const [invoicefile, setInvoiceFile] = React.useState();
 const [invoiceTemp, setInvoiceTemp] = useState([]);
 const [indinvoice, setIndInvoice] = useState([]);
 let tempInvoice = (invoiceTemp || []).sort((a,b) => {return b.id-a.id});
 const abc = tempInvoice || [].status;

 // const templateType = [{ name: 'Template' }, {name: 'General'}];
 // const [typeval, setTypeVal] = useState(null);

 const [page, setPage] = React.useState(0);
 const [rowsPerPage, setRowsPerPage] = React.useState(5);

 const handleChangePage = (event, newPage) => {
   setPage(newPage);
 };

 const handleChangeRowsPerPage = event => {
  setRowsPerPage(parseInt(event.target.value, 10));
  setPage(0);
 };

 useEffect( () => {
  getVendorForm(group_id)
   .then(resp => setVendors(resp.data))
 }, []);

 useEffect( () => {
  getPorders(group_id)
   .then(resp => setPOrders(resp.data))
 }, []);

 useEffect(() => { 
  if(abc){
  const interval = setInterval(() => { 
    getIndInvoiceTemp(group_id) 
    .then(resp => setInvoiceTemp(resp.data))  
    }, 10000); 
  return () => clearInterval(interval);
  }
  else {

  }
 }, [invoiceTemp]);

 useEffect( () => {
  getIndInvoice(group_id)
   .then(resp => setIndInvoice(resp.data))
 }, []);

 const handleUploadInvoice = () => {
   setInvoiceFile(document.getElementById('fileinvoice').files[0]);
 }

 const handleSubmit = async (event) => {
      const formData = new FormData()
      formData.append( 'file', invoicefile )
      const inv = await uploadIndInvoice(formData);
      const aaa = inv.data.filename;
      const invExt = inv.data.mimetype;
      const invpath = "/Data/INVOICE/RawData/PDFData/" + aaa;

      const foundInvPath = indinvoice.find(x=>x.inv_file === invpath);

       const invoiceTemp = {
        filename: aaa,
        vendor_id: selectedVendorName,
        po_no: selectedPO,
        status: 'Processing',
        user_id: user.id,
        group_id: group_id
      }

      const invoice = {
        vendor_id: selectedVendorName,        
        porder_id: selectedPO,
        inv_file: invpath,
        inv_ext: invExt,
        type: selectedType,
        status: "Processed",
        project_id: project_id,
        user_id: user.id,
        group_id: group_id,
      }
      if(foundInvPath){
        toast.error("This invoice already exists!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
      }
      else{
        postIndInvoiceTemp({invoiceTemp, invoice})
        .then(resp => {
        getIndInvoiceTemp(group_id) 
        .then(resp => setInvoiceTemp(resp.data))
        setVendorVal(null)
        setInvoiceFile('')
        setPorderVal(null)
          toast.success("Invoice is being processed, Once the status is \"Ready to review\", you can review the invoices !!!",
            {position: toast.POSITION.TOP_CENTER, autoClose: 6000})
        })
      }
      
  }

	return (
        <Fragment>
         <div className="container-fluid">
            <div className="row">
            {isLoading ? (
              <Backdrop className={classes.circular} open={isLoading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
             <div className="col-sm-12 col-xl-6">
               <div className="ribbon-wrapper card">
                <div className="card-body">
                  <div className="ribbon ribbon-bookmark ribbon-primary">Upload Invoice</div>
                    <div className="col-md-12">
                      <Autocomplete
                        freeSolo
                        options={approvedVendors}
                        getOptionLabel={option => option.supname}
                        value={vendor_val}                      
                        onChange={(event, newValue) => {
                          setVendorVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params} label="Vendor" margin="normal" variant="outlined" helperText="Please select Vendor" fullWidth />
                        )}
                      />
		                </div>
                    { selectedVendor ?
                    <div className="col-md-12">
                    <div className="row">
                     <div className="col-md-6">
                      <TextField value={selectedType} margin="normal" label="Type" variant="outlined" disabled />
                     </div>
                     <div className="col-md-6">
                        <Autocomplete 
                          freeSolo
                          options={filterPo}
                          getOptionLabel={option => ('PO-000').concat(option.id.toString())}
                          value={porder_val}                      
                          onChange={(event, newValue) => {
                            setPorderVal(newValue);
                          }}            
                          renderInput={params => (
                            <TextField {...params} label="PO" margin="normal" variant="outlined" helperText="Please select PO" fullWidth />
                          )}
                        />
                     </div>
                    </div>
                    </div>
                    : null
                    }
		                <div className="col-md-12">
		                  <label className="col-form-label text-body" htmlFor="message-text">Attach Invoice:</label>
                      <TextField id="fileinvoice" type="file" margin="dense" variant="outlined" onChange={handleUploadInvoice} fullWidth/>
                    </div>
                </div>
                {invoicefile ?
                <div className="card-footer text-center">
                  <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={handleSubmit}>Submit</button>
                </div>
                : null
                }
              </div>
             </div>
             )}
             <div className="col-sm-12 col-xl-6">
              <div className="card">
                <div className="card-block row">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="table-responsive-sm">
                      <table className="table table-bordered table-hover">
                       <thead className="table-info">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Filename</th>
                          <th scope="col">Vendor Name</th>
                          <th scope="col">Status</th>
                        </tr>
                       </thead>
                       <tbody>
                        {(tempInvoice || []).map((n,i) => {
                          return(
                           <tr>
                            <td>{i+1}</td>
                            <td>{n.filename}</td>
                            <td>{n.vendor_id}</td>
                            {n.status === 'Processing' ?
                              <td style={{color: "blue", fontSize:16}}><b>{n.status}</b></td>
                              :
                              <td style={{color: "green", fontSize:16}}><b>{n.status}</b></td>
                            }
                           </tr>
                          )
                         })
                        }
                       </tbody>
                      </table>
                    </div>
                    <div className="col-sm-12 col-lg-12 col-xl-12">
                     <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={tempInvoice.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                     />
                    </div>
                  </div>
                </div>
              </div>
             </div> 
            </div>
          </div>
        </Fragment>
    );
}
export default InvoiceProcessing;
