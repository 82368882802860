import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../../../components/common/breadcrumb";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import myEventsList from "./events";
import 'react-big-calendar/lib/sass/styles.scss';
import {getHoliday, getBookLeave } from '../../../actions/asyncActions';

const localizer = momentLocalizer(moment)
let allViews = Object.keys(Views).map(k => Views[k])

const Attendance = () => {
    const user = JSON.parse(localStorage.getItem("m_users"));
    const group_id = user.group_id;

    const [holidays, setHolidays] = useState([]);
    const [bookLeaves, setBookLeaves] = useState([]);

    const filterBookLeave = bookLeaves.filter(x=>x.status === 'Approved');

    let leaveData = filterBookLeave;

    let holidayData = holidays;
    let holidayItems_data = [];
    holidayData.forEach((item, i) => {
        holidayItems_data.push({ 
            id: i+1, title: 'Holiday', start: item.date, end: item.date
        })
    });

    leaveData.forEach((item, i) => {
        holidayItems_data.push({ 
            id: i+1, title: 'Leave', start: item.start_date, end: item.end_date
        })
    });

    useEffect(() => {
        getHoliday(group_id)
        .then(resp => setHolidays(resp.data))
    }, [])

    useEffect(() => {
        getBookLeave(group_id)
        .then(resp => setBookLeaves(resp.data))
    }, [])

    // Returns an array of dates between the two dates
  var getDates = function(startDate, endDate) {
    var dates = [],
        currentDate = startDate,
        addDays = function(days) {
          var date = new Date(this.valueOf());
          date.setDate(date.getDate() + days);
          return date;
        };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  };
  
  // Usage
  var dates = getDates(new Date(2021,0,1), new Date(2021,11,31));
  let allDate = []                                                                                                        
  dates.forEach(function(date) {
    allDate.push({
        date: date, day: date.getDay()
    })
  });

  console.log("allDate", allDate)
  const filterWeekOff = allDate.filter(x=>x.day === 0 || x.day === 6);
  console.log("filterWeekOff", filterWeekOff)

  filterWeekOff.forEach((item, i) => {
    holidayItems_data.push({ 
        id: i+1, title: 'Week Off', start: item.date, end: item.date
    })
  });


    return (
        <Fragment>
            <Breadcrumb parent="Attendance" title="Attendance" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Attendance</h5>
                            </div>
                            <div className="card-body">
                                <Calendar
                                    localizer={localizer}
                                    scrollToTime={new Date()}
                                    defaultDate={new Date()}
                                    onSelectEvent={event => alert(event.title)}
                                    views={allViews}
                                    events={holidayItems_data}
                                    eventOverlap
                                    dragRevertDuration={500}
                                    dragScroll
                                    showMultiDayTimes
                                    step={60}
                                    startAccessor="start"
                                    endAccessor="end"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Attendance;