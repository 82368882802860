import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getInvoice, editInvoice, getInvoiceItems } from '../../actions/asyncActions';
//import "file-viewer";
import { Document, Page, pdfjs } from 'react-pdf';
//import Zoom from 'react-img-zoom';
// "sequelize": "^4.44.3",
import { ThumbsUp, ThumbsDown } from 'react-feather';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
import Item from "./Item";
import Carousel from "react-elastic-carousel";
import Table from "./Table";

const ViewApprovedInvoice = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const user = JSON.parse(localStorage.getItem("m_users"));
  const group_id = user.group_id;
  const location = props.location;
  const invoice_id = location.state.id;
  const invoiceNo = location.state.invoiceNo;
  const invoiceDate = location.state.invoiceDate;
  const custName = location.state.custName;
  const email = location.state.email;
  const website = location.state.website;
  const amount = location.state.amount;
  const invFile = location.state.invFile;
  const invExt = location.state.invExt;
  const filInvIt = location.state.filterInvItems;

  const [invoiceItems, setInvoiceItems] = useState([]);
  const filterInvoiceItems = invoiceItems.filter(x => x.invoice_id === invoice_id);

  const [invoicedetails, setInvoice] = useState([]);
  const filterInvoice = invoicedetails.filter(x => x.id === invoice_id);

  const initialFormState = {
    invoice_no: invoiceNo, invoice_date: invoiceDate, customer_name: custName, email_address: email,
    customer_website: website, invoice_amount: amount, inv_file: invFile, inv_ext: invExt
  };
  const [data, setData] = useState(initialFormState);

  const tableData = {
    data: filInvIt,
    resolve: () => { },
    updatedAt: new Date()
  };

  const comonscol = [
    { title: "Description", field: "description" },
    { title: "HSN", field: "hsn" },
    { title: "GST", field: "gst" },
    { title: "Qty", field: "qty" },
    { title: "Rate", field: "rate" },
    { title: "Amount", field: "amount" },
  ];

  //const invoicePath = `http://localhost:9004/${data.inv_file}`;
  //const pathpath = 'http://172.105.62.208:9004'
  //const pathpath = 'http://172.105.38.239:9004'
  //const pathpath = 'http://172.105.41.149:9004'
  //const pathpath = 'https://testerp.nu-pie.com'
  const pathpath = 'http://172.105.41.149:9004'
  const invoicePath = `${pathpath}${data.inv_file}`
  const resultPath = `${pathpath}${data.res_file}`

  console.log("invoicePath", invoicePath);
  const invoiceExtension = data.inv_ext;
  const [numPages, setNumPage] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPage(numPages);
  }

  const goToPrevPage = () => {
    setPageNumber(pageNumber - 1);
  }
  const goToNextPage = () => {
    setPageNumber(pageNumber + 1);
  }

  useEffect(() => {
    getInvoice(group_id)
      .then(resp => setInvoice(resp.data))
  }, []);

  useEffect(() => {
    getInvoiceItems(group_id)
      .then(resp => setInvoiceItems(resp.data))
  }, []);

  // const handleChange = event => {
  //    setData({
  //   ...data,
  //   [event.target.name]: event.target.value
  // });
  // };

  const handleBack = () => {
    props.history.push(`${process.env.PUBLIC_URL}/minvoice`)
  }

  const handleUpdate = () => {
    const invoiceData = {
      id: invoice_id,
      invoice_no: data.invoice_no,
      customer_name: data.customer_name,
      customer_website: data.customer_website,
      email_address: data.email_address,
      invoice_amount: data.invoice_amount,
    }
    editInvoice(invoiceData)
      .then(resp => {
        getInvoice(group_id)
          .then(resp => setInvoice(resp.data))
        toast.success("The Invoice updated successfully !!!", { position: toast.POSITION.TOP_CENTER, autoClose: 3000 })
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

  const approveInvoice = () => {
    const data = {
      id: invoice_id,
      status: 'Approved'
    }
    editInvoice(data)
      .then(resp => {
        getInvoice(group_id)
          .then(resp => setInvoice(resp.data))
        toast.success("Invoice Approved !!!", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        props.history.push(`${process.env.PUBLIC_URL}/minvoice`)
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

  const rejectInvoice = () => {
    const data = {
      id: invoice_id,
      status: 'Rejected'
    }
    editInvoice(data)
      .then(resp => {
        getInvoice(group_id)
          .then(resp => setInvoice(resp.data))
        toast.warn("Invoice Rejected !!!", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 })
        props.history.push(`${process.env.PUBLIC_URL}/minvoice`)
      })
      .catch(err => {
        console.log("Error", err);
      })
  }

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-sm-12 col-xl-6 text-left">
            <button className="btn btn-pill btn-info-gradien btn-lg" type="button" onClick={handleBack}>Back</button>
          </div>
        </div>
        <div className="row m-t-15">
          <div className="col-sm-12 col-xl-7">
            <div className="card">
              <div className="card-body" style={{ backgroundColor: 'paleturquoise' }}>
                <div className="media">
                  <div className="media-body">
                    <h4 className="f-w-600 text-center">
                      <u>Invoice Details</u>
                    </h4>
                  </div>
                </div>
                <div className="job-description">
                  <form className="form theme-form">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Invoice No:</label>
                          <input className="form-control" name="invoice_no" type="text"
                            value={data.invoice_no} autoComplete="off" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Customer Name:</label>
                          <input className="form-control" name="customer_name" type="text"
                            value={data.customer_name} autoComplete="off" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Website:</label>
                          <input className="form-control" name="customer_website" type="text"
                            value={data.customer_website} autoComplete="off" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Invoice Amount:</label>
                          <input className="form-control" name="invoice_amount" type="text"
                            value={data.invoice_amount} autoComplete="off" />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor="exampleFormControlInput1">Email Address:</label>
                          <input className="form-control" name="email_address" type="text"
                            value={data.email_address} autoComplete="off" />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div className="card-footer text-center" style={{ backgroundColor: 'paleturquoise' }}>
                {/* Invoice Item details comes here */}
                <div className="media">
                  <div className="media-body">
                    <h4 className="f-w-600 text-center">
                      <u>Invoice Item details</u>
                    </h4>
                  </div>
                </div>
                {/*
         <div className="table-responsive-lg"> 
          <Table tableData={tableData} comonscol={comonscol} />
         </div>
         */}
                <div className="table-responsive-lg">
                  <table className="table table-bordered table-hover">
                    <thead className="table-success">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Description</th>
                        <th scope="col">HSN</th>
                        <th scope="col">GST</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Rate</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filterInvoiceItems.map((n, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>{n.description}</td>
                            <td>{n.hsn}</td>
                            <td>{n.gst}</td>
                            <td>{n.qty}</td>
                            <td>{n.rate}</td>
                            <td>{n.amount}</td>
                          </tr>
                        );
                      })
                      }
                    </tbody>
                  </table>
                </div>
                {/*
      	 <Carousel>
	        {filterInvoiceItems.map((n,i) => {
	        	let aaa = descrip;
	        	console.log("whattaaaa", aaa);
	          return (
	            <Item>
	              <input type="text" value={aaa[i].description} onChange={() => handleChangeItems(n)}/>
	            </Item>
	          )
	        })
	       }
        </Carousel>
        */}

              </div>
            </div>
          </div>
          {/*
      <div className="col-sm-12 col-xl-6">
       <nav>
        <button className="btn btn-light" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
        <button className="btn btn-secondary" onClick={goToNextPage}>Next</button>
       </nav>
       <Document
          file={`${invoicePath}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>Page {pageNumber} of {numPages}</p>

        <Zoom
		 img={`${invoicePath}`}
		 zoomScale={3}
		 width={840}
		 height={720}
	    />
      
      
       <img src={`${invoicePath}`} width="840" height="720" crossOrigin="anonymous"/>
      <file-viewer
       filename={`${data.inv_file}`}
       url= {invoicePath}
      ></file-viewer>
      </div>
      */}
          <div className="col-sm-12 col-xl-5" style={{ width: '840px', height: '720px' }}>
            {invoiceExtension === 'application/pdf' ?
              <div>
                <nav>
                  <button className="btn btn-light" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
                  <button className="btn btn-secondary" onClick={goToNextPage}>Next</button>
                </nav>
                <Document
                  file={invoicePath}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  <Page pageNumber={pageNumber} />
                </Document>
                <p>Page {pageNumber} of {numPages}</p>
              </div>
              :
              <PinchZoomPan>
                <img alt='Test Image' src={invoicePath} />
              </PinchZoomPan>
            }
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ViewApprovedInvoice;
