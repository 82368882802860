import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { TextField } from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import { ToastContainer, toast } from 'react-toastify';
import { getSettings, editSettings } from '../../actions/asyncActions';

const Settings = (props) => {
	const user = JSON.parse(localStorage.getItem("m_users"));
	const group_id = user.group_id;

	const [settings, setSettings] = useState([]);

	const [values, setValues] = useState({})
	const currency = [{name: 'INR'}, {name:'USD'}];
	const [currency_val, setCurrencyVal] = useState(null);
	const selectedCurrency = currency_val && currency_val.name;

	const handleUpdate = () => {
		const getSetData = settings.find(x=>x.group_id === group_id);
		const settingsId = getSetData ? getSetData && getSetData.id : null;
		const data = {
			id: settingsId,
			currency: selectedCurrency
		}
		//Update Settings
		editSettings(data)
		.then(resp => {
			getSettings(group_id)
			.then(resp => setSettings(resp.data))
			setCurrencyVal(null)
			toast.success("Settings updated successfully!!!", {position: toast.POSITION.TOP_CENTER, autoClose: 3000})
		})
		.catch(err => {
			console.log(err)
		})
	}

	useState( () => {
		getSettings(group_id)
		.then(resp => setSettings(resp.data))
	})

	return(
	 <Fragment>
	  <Breadcrumb title="Settings" parent="Group" />
	    <div className="container-fluid">
            <div className="row">
             <div className="col-sm-12 col-xl-6">
              <div className="ribbon-wrapper card">
                <div className="card-body text-center">
                  <div className="ribbon ribbon-bookmark ribbon-secondary">My Settings</div>
                    <div className="form-row">
                     <div className="col-xl-12">
                      <label className="col-form-label text-body" htmlFor="message-text">Currency:</label>
                      <Autocomplete
                        freeSolo
                        options={currency}
                        getOptionLabel={option => option.name}
                        value={currency_val}                      
                        onChange={(event, newValue) => {
                          setCurrencyVal(newValue);
                        }}            
                        renderInput={params => (
                          <TextField {...params}margin="normal" variant="outlined" helperText="Please select Currency" fullWidth />
                        )}
                      />
                     </div>
                     <div className="col-xl-12">
                      <button className="btn btn-success" onClick={handleUpdate}>Update</button>
                     </div>
                    </div>
                </div>
              </div>
             </div>
            </div>
        </div>
	 </Fragment>
	);
	
}

export default Settings;