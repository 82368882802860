import {
    Home, Box, DollarSign, UserPlus, Users, Chrome, Settings, Airplay, Slack, FolderPlus, File, Trello,
    Command, Cloud, Book, FileText, Server, Image, Sliders, Map, GitPullRequest, Calendar, Edit, Mail,
    MessageSquare, UserCheck, Layers, HelpCircle, Database, Headphones, Mic, ShoppingBag, Search,
    AlertOctagon, Lock, Briefcase, BarChart, Grid, Clock
} from 'react-feather';

const user = JSON.parse(localStorage.getItem("m_users"));
const userRole = user && user.role || [];

let superAdminRole = [];
if(userRole === 'superadmin') {
    superAdminRole = userRole;
}

let adminRole = [];
if(userRole === 'superadmin' || userRole === 'Admin') {
    adminRole = userRole;
}

let vendorRole = [];
if(userRole === 'Vendor') {
    vendorRole = userRole;
}

let employeeRole = [];
if(userRole === 'Employee') {
    employeeRole = userRole;
}

export const MENUITEMS = [
    {
        title: 'Dashboard', icon: Home, type: 'sub', badgeType: 'primary', active: false, role: adminRole, children: [
            { path: '/dashboard/default', title: 'Default', type: 'link' }
        ]
    },
    {
        title: 'Evaluation Form', icon: Book, type: 'link', path: '/vendorform', active: false, role: vendorRole
    },
    {
        title: 'Invoice Processing', icon: Image, type: 'link', path: '/vinvoice', active: false, role: vendorRole
    },
    {
        title: 'Purchase Order(PO)', icon: Sliders, type: 'link', path: '/vpo', active: false, role: vendorRole
    },
    {
        title: 'P2P', icon: Book, type: 'sub', role: adminRole, children: [
            {
                title: 'Vendor Master', type: 'sub', children: [
                    { title: 'Send Invitation', type: 'link', path: '/sendinvite' },
                    { title: 'Vendor Form', type: 'link', path: '/vendorform' },
                    { title: 'Vendor List', type: 'link', path: '/vendorlist' }
                ]
            },
            { title: 'Indent', type: 'link', path: '/projectindent' },
            { title: 'Purchase Order', type: 'link', path: '/po' },
        ]
    },
    {
        title: 'O2C', icon: Briefcase, type: 'sub', role: adminRole, children: [
            {
                title: 'Sales', type: 'sub', children: [
                    { title: 'Client Management', type: 'link', path: '/client' },
                    { title: 'Project Management', type: 'link', path: '/project' },
                    { title: 'BOM', type: 'link', path: '/bom' }
                ]
            },
        ]
    },
    {
        title: 'Inventory', icon: Command, type: 'sub', role: adminRole, children: [

            { title: 'Material Management', type: 'link', path:'/mmt' },
            { title: 'Quality', type: 'link', path:'/mquality' },
            { title: 'Quality Rejections', type: 'link', path:'/qcrejection' },
            { title: 'Material Rejection', type: 'link', path:'/mrt'},
            {
                title: 'Inventory Master', icon: Command, type: 'sub', role: adminRole, children: [
                    { title: 'Items', type: 'link', path:'/inventorymaster' },
                    { title: 'Inventory', type: 'link', path:'/inventory' },
                    { title: 'Warehouse', type: 'link', path:'/warehouse' }
                ]
            }
        
        ]
    },
    {
        title: 'Accounts Payable', icon: Airplay, type: 'sub', active: false, role: adminRole, children: [
           { path: '/minvoice', title: 'Invoicing', type: 'link' },
           { path: '/invoice', title: 'Indian Invoicing', type: 'link' },
           { path: '/threeway', title: '3-Way Match', type: 'link' },
           { path: '/paymentprocess', title: 'Payment Processing', type: 'link' },
        ]
    },
    {
        title: 'HRMS', icon: Grid, type: 'sub', role: adminRole, children: [
           { title: 'Employee Master', type: 'sub', role: adminRole, children: [
                { title: 'Employee Creation', type: 'link', path:'/employee' },
                { title: 'Employee Table', type: 'link', path:'/employeemaster' },
                { title: 'Department', type: 'link', path: '/department'},
                { title: 'Compensation', type: 'link', path: '/ccompensation'}
             ]
           },
           { title: 'Leave Management', path: '/leaves', type: 'link' },
           { title: 'Payroll', type: 'sub', role: adminRole, children: [
                { title: 'Run Payroll', type: 'link', path: '/payroll' },
                { title: 'Payroll List', type: 'link', path: '/payrollfinal'},
                { title: 'Approved Payroll', type: 'link', path: '/payrollapproved'},
                { title: 'Stopped Payroll', type: 'link', path: '/payrollstopped'}, 
            ]
           },
           { title: 'Time Management', type: 'sub', role: adminRole, children: [
                { title: 'Projects', type: 'link', path: '/tsproject' },
                { title: 'Attendance', type: 'link', path: '/attendance'} 
            ]
           },
        ]
    },
    {
        title: 'My Form', icon: Book, type: 'link', path: '/employeeform', active: false, role: employeeRole
    },
    {
        title: 'Leave Management', icon: Calendar, type: 'link', path: '/leaves', active: false, role: employeeRole
    },
    {
        title: 'IT Declaration', icon: DollarSign, type: 'link', path: '/itdeclaration', active: false, role: employeeRole
    },
    {
        title: 'Timesheet', icon: Clock, type: 'link', path: '/timesheet', active: false, role: employeeRole
    },
    {
        title: 'Statements', icon: Trello, type: 'sub', active: false, role: adminRole, children: [
            { path: '/banking', title: 'Bank Statement', type: 'link' },
            { path: '/credit', title: 'Credit Card ', type: 'link' }
        ]
    },
    {
        title: 'Logs', icon: AlertOctagon, type: 'link', path: '/logs', active: false, role: adminRole
    },
    {
        title: 'User Management', icon: Airplay, type: 'link', path:'/usersss', active: false, role: adminRole
    },
    {
        title: 'Group', icon: UserPlus, type: 'link', path: '/group', active: false, role: superAdminRole
    },
    {
        title: 'Settings', icon: Settings, type: 'link', path: '/settings', active: false, role: adminRole
    }


    
]

{/*
    {
        title: 'User Management', icon: Airplay, type: 'sub', active: false, role: adminRole, children: [
            { path: '/usersss', title: 'User', type: 'link' },
            { path: '/usersmanager', title: 'Manager', type: 'link' }
        ]
    },
    { path: '/minvoice', title: 'Malaysian Invoicing', type: 'link' },
    { path: '/invoice', title: 'Indian Invoicing', type: 'link' }, 
    {
        title: 'Indent', type: 'sub', children: [
            { title: 'Project Indent', type: 'link', path: '/projectindent' },
            { title: 'Non Project Indent', type: 'link', path: '/nonprojectindent' }
        ]
    },
*/}