import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Paper, Stepper, Step, StepLabel, Button, Link, Typography, Dialog, DialogContent, 
  DialogActions, Slide, Backdrop , CircularProgress, Grid } from '@material-ui/core';
import { Error as ErrorIcon } from "@material-ui/icons";
import ProjectCreation from './components/ProjectCreation';
import CostSheet from './components/CostSheet';
import MasterBOM from './components/MasterBOM';
//import UploadTabs from './procomp/UploadTabs';

import { postProjects, getProjects, getClients, runScript, runBOMScript, BulkUploadCostSheet, BulkUploadBOM,
  pushCostSheet, getAllUser, sendManagerEmail } from '../../../actions/asyncActions'
import { toast } from 'react-toastify';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    // [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
    //   width: '90%',
    //   marginLeft: 'auto',
    //   marginRight: 'auto',
    // },
  },
  paper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  footer: {
    padding: theme.spacing(2),
    marginTop: 'auto',
    backgroundColor: 'white',
  },
  deleteicon: {
    color: '#ff8f64'
  },
  close: {
    display: 'flex',
    alignItems: 'center'
  },
  circular: {
    //alignItems: 'center',
    //marginTop: theme.spacing(3),
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  spacer: {
    flexGrow: 1
  },
}));

const steps = ['Project Creation', 'Cost Sheet', 'Master BOM'];


const ProjectStepper = (props) => {
  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem("m_users"));
  const mgr_id = user.mgr_id;
  const group_id = user.group_id;

  const [ clients, setClient ] = useState([])
  useEffect( () => {
    getClients(group_id)
    .then(resp => setClient(resp.data))
  }, []);

  const [projects, setProject] = useState([]);
  useEffect( () => {
    getProjects(group_id)
    .then(resp => setProject(resp.data))
  }, []);

  const [users, setUsers] = useState([]);
  useEffect( () => {
    getAllUser(group_id)
    .then(resp => setUsers(resp.data))
  }, []);

  const filterProjectUser = users.filter(x=>x.department === 'Project')
  const filterSalesUser = users.filter(x=>x.department === 'Sales')

  const [values, setValues] = useState({});
  const handleChange = event => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const [StartDate, setStartDate] = React.useState(new Date());
    const handleStartDateChange = date => {
      setStartDate(date);
    };

    var nextDate = new Date(StartDate)
    nextDate.setDate(nextDate.getDate() + 1);

    const [EndDate, setEndDate] = React.useState(new Date(nextDate));
    const handleEndDateChange = date => {
      setEndDate(date);
    };

    const [costsheetfile, setFile] = useState();
    const handleUploadCostSheet = (files) => {
      setFile(document.getElementById('file').files[0]);
    };

    const [bomfile, setAadhar] = useState([]);
    const handleUploadBOM = async (event) => {
      setAadhar(document.getElementById('files').files[0]);
    }

    const found = clients.find(s=> s.id === values.client_id);
    const ClientGST = found && found.gstin;
    const ClientAddress = found && found.billing_address;

    const getProjectId = users.find(x=>x.id === values.project_incharge)
    const projectInchargeEmail = getProjectId && getProjectId.email;
    const getSalesId = users.find(x=>x.id === values.sales_incharge)
    const salesInchargeEmail = getSalesId && getSalesId.email;

    const validateForm = (data) => {
       const errors = [];
       if(activeStep === 1){
         if(!costsheetfile){
           errors.file = "Please upload the costsheet as it is mandatory";
         }
       }
       else if(!data.name) {
         errors.name = "Project Num is required";
       }
       return errors;
    }

    const ProjectNum = values.name;

    var [isLoading, setIsLoading] = useState(false);

  const handleSubmit = () => {
      const formData = new FormData()
      formData.append( 'file', costsheetfile ) 

      const formData2 = new FormData();
      formData2.append( 'file', bomfile );
      
      const findMgr = users.find(x=>x.id === mgr_id)
      const mgrEmail = findMgr && findMgr.email;

      const emailData = {
        email: mgrEmail,
        type: 'project'
      }

      const data = {
        name: values.name,
        po_value: values.po_value,
        start_date: StartDate,
        end_date: EndDate,
        billing_address: ClientAddress,
        gstin: ClientGST,
        project_incharge: values.project_incharge,
        project_email: projectInchargeEmail,
        sales_incharge: values.sales_incharge,
        sales_email: salesInchargeEmail,
        status: 'submitted',
        client_id: values.client_id,
        bom_applicable: values.bom_applicable,
        mgr_id: user.mgr_id,
        user_id: user.id,
        group_id: group_id,
      }

      setIsLoading(true);
      if (data) {
        setTimeout(() => {      

      BulkUploadCostSheet(formData)
      BulkUploadBOM(formData2)
      .then(resp => {
        postProjects(data)
          // .then(resp => {
          //     getProjects(group_id)
          //     .then(resp => setProject(resp.data))
          //     setValues({ id: '', name:'', start_date:'', end_date:'', billing_address:'', gstin:'', client_id:''});        
          //   })
          //   .catch(err => {
          //     console.log("Error", err);
          //   })

          // .then(resp => {
          //     window.location.hash=`/${group_id}/pos/projectmgmt`
          // })

        })


      setIsLoading(false)

          getProjects(group_id)
          .then(resp => {
            if(mgrEmail == '') {
            } else {
            sendManagerEmail(emailData)
            }
            setProject(resp.data)
            setValues({ id: '', name:'', start_date:'', end_date:'', billing_address:'', gstin:'', client_id:''});
          })
      
     .then(resp => {
            props.history.push(`${process.env.PUBLIC_URL}/project`);
          })
        }, 5000);
      }

        setActiveStep(activeStep + 1);
  }
 
  const getStepContent = (step) => {
      switch (step) {
        case 0:
          return <ProjectCreation values={values} handleChange={handleChange} StartDate={StartDate} nextDate={nextDate}
           EndDate={EndDate} clients={clients} handleStartDateChange={handleStartDateChange} handleEndDateChange={handleEndDateChange}
           ClientGST={ClientGST} ClientAddress={ClientAddress} errors={errors} projectUser={filterProjectUser}
           salesUser={filterSalesUser} projectEmail={projectInchargeEmail} salesEmail={salesInchargeEmail} />;
        case 1:
          return <CostSheet handleUploadCostSheet={handleUploadCostSheet} errors={errors} ProjectNum={ProjectNum}/>;
        case 2:
          return <MasterBOM values={values} handleChange={handleChange} handleUploadBOM={handleUploadBOM} ProjectNum={ProjectNum}/>;
        default:
          throw new Error('Unknown step');
      }
    }

  const [activeStep, setActiveStep] = React.useState(0);
  console.log("activeStep", activeStep)

  const [open_found, setFoundOpen] = React.useState(false);

  const handleFoundClose = () => {
    setFoundOpen(false);
  };

  const [errors, setError] = useState({});

  const handleNext = () => {
    const found = projects.find(s=> s.name === values.name);
    if(found){
      setFoundOpen(true);
    } else{
      const errors = validateForm(values);
       if(Object.keys(errors).length) {
        setError(errors);
        return;
       }
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleProjectBack = () => {
    //window.location.hash=`/${group_id}/pos/projectmgmt`
    props.history.push(`${process.env.PUBLIC_URL}/project`);
  };


  return (
    <div>

    {isLoading ? (

      <Backdrop className={classes.circular} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    ) : (           
      
    

    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center">
            Project Creation
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length  ? (
              <React.Fragment>
               {/* <Typography variant="h5" gutterBottom align="center">
                  Order is Successfully submitted
                </Typography> 
              <UploadTabs /> */}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}

                <div className={classes.close}>
                  <Button onClick={handleProjectBack} variant="contained" color="Secondary">
                    Back
                  </Button>
                  <span className={classes.spacer} />

                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button onClick={handleBack} className={classes.button}>
                        Prev
                      </Button>
                    )}

                    {activeStep === steps.length - 1 ?

                      <Button variant="contained" color="primary" onClick={handleSubmit} className={classes.button} >
                        Submit
                      </Button>
                      :
                      <Button variant="contained" color="primary" onClick={handleNext} className={classes.button} >
                        Next
                      </Button> 
                    }               

                   {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1 ? 'Submit for the day' : 'Next'}
                    </Button> */}

                  </div>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
       
      </main>
    </React.Fragment>

  )}

  {/***** Project Found Notification *****/}
        
      <Dialog open={open_found} onClose={handleFoundClose} 
        aria-labelledby="form-dialog-title" 
        TransitionComponent={Transition}
       >
        <DialogContent>
          <Grid container spacing={24}>
            <Grid item xs={12} sm={12}>
              <Typography align="center">
                 <ErrorIcon className={classes.deleteicon} style={{height:'40px', width:'40px'}}/>
              </Typography>              
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h6" align="center" gutterBottom>
                Please Check, This Project Already Exists...!
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFoundClose} color="primary" size="sm" round >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    </div>
  );
}
export default ProjectStepper;