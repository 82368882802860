import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../components/common/breadcrumb';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIndInvoice, editIndInvoice, getIndInvoiceItems } from '../../actions/asyncActions';
//import "file-viewer";
import { Document, Page, pdfjs } from 'react-pdf';
import {FormHelperText, TextField} from '@material-ui/core';
//import Zoom from 'react-img-zoom';
// "sequelize": "^4.44.3",
import { ThumbsUp, ThumbsDown } from 'react-feather';
import PinchZoomPan from "react-responsive-pinch-zoom-pan";
//import Item from "./Item";
import Carousel from "react-elastic-carousel";
import Table from "./Table";
import DatePicker from "react-datepicker";
import {Autocomplete} from '@material-ui/lab';

const ViewVendorInvoice = (props) => {
 pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
 const user = JSON.parse(localStorage.getItem("m_users"));
 const group_id = user.group_id;
 const location = props.location;
 const invoice_id = location.state.id;
 const invoiceNo = location.state.invoiceNo;
 const invoiceDate = location.state.invoiceDate;
 const custName = location.state.custName;
 const email = location.state.email;
 const website = location.state.website;
 const amount = location.state.amount;
 const invFile = location.state.invFile;
 const invExt = location.state.invExt;
 const remarkS = location.state.remarks;
 const vendorName = location.state.vendor_name;
 const tempId = location.state.temp_id;
 const gstNo = location.state.gst_no;
 const cgst = location.state.cgst;
 const sgst = location.state.sgst;
 const igst = location.state.igst;
 const invType = location.state.invType;
 const filInvIt = location.state.filterInvItems;

 const addInvAmount = filInvIt.reduce((a,b) => a + parseFloat(b.amount * (1+parseFloat(b.gst/100))), 0);
 
 const [invoiceItems, setInvoiceItems] = useState([]);
 const filterInvoiceItems = invoiceItems.filter(x=>x.invoice_id === invoice_id);

 const [invoicedetails, setInvoice] = useState([]);
 const filterInvoice = invoicedetails.filter(x=>x.id === invoice_id);

 const initialFormState = {invoice_no: invoiceNo, invoice_date:invoiceDate, customer_name: custName, email_address: email, 
                         customer_website: website, invoice_amount: amount, inv_file: invFile, inv_ext: invExt, remarks: remarkS,
                         vendor_name: vendorName, temp_id: tempId, gst_no: gstNo, cGst: cgst, sGst: sgst, iGst: igst, invType: invType};
 const [data, setData] = useState(initialFormState);
 const initialGetDate = {invoice_date: invoiceDate}
 const [aaaDate, setInvoiceDate] = useState(initialGetDate);
 const [errors, setError] = React.useState({});

 const invoiceType = [{ name: 'Product' }, {name: 'Service'}];
 const [typeval, setTypeVal] = useState(null);

 const tableData = {
  data: filInvIt,
  resolve: () => {},
  updatedAt: new Date()
};

const comonscol = [
  { title: "Description", field: "description" },
  { title: "HSN", field: "hsn" },
  { title: "GST", field: "gst" },
  { title: "Qty", field: "qty" },
  { title: "Rate", field: "rate" },
  { title: "Amount", field: "amount" },
];

 //const invoicePath = `http://localhost:9004/${data.inv_file}`;
 //const pathpath = 'http://172.105.62.208:9004'
 //const pathpath = 'http://172.105.38.239:9004'
 //const pathpath = 'http://172.105.41.149:9004'
 //const pathpath = 'https://testerp.nu-pie.com'
 const pathpath = '172.105.41.149:9004'
 const invoicePath = `${pathpath}${data.inv_file}`
 const resultPath = `${pathpath}${data.res_file}`

 const invoiceExtension = data.inv_ext;
 const [numPages, setNumPage] = useState(null);
 const [pageNumber, setPageNumber] = useState(1);

 const onDocumentLoadSuccess = ({ numPages }) => {
   setNumPage(numPages);
 }

 const goToPrevPage = () => {
  setPageNumber(pageNumber - 1);
 }
 const goToNextPage = () => {
  setPageNumber(pageNumber + 1);
 }

 useEffect( () => {
  getIndInvoice(group_id)
   .then(resp => setInvoice(resp.data))
 }, []);

 useEffect( () => {
  getIndInvoiceItems(group_id)
   .then(resp => setInvoiceItems(resp.data))
 }, []);

 const handleChange = event => {
    setData({
    ...data,
    [event.target.name]: event.target.value
  });
 };

 const handleDateChange = date => {
  setInvoiceDate({invoice_date: date})
 }

 const handleBack = () => {
    props.history.push(`${process.env.PUBLIC_URL}/vinvoice`) 
 }

 const handleUpdate = () => {
  const invoiceData = {
    id: invoice_id,
    invoice_no: data.invoice_no,
    customer_name: data.customer_name,
    invoice_date: aaaDate.invoice_date,
    email_address: data.email_address,
    gst_no: data.gst_no,
    cgst: data.cGst,
    sgst: data.sGst,
    igst: data.iGst,
    invoice_amount: data.invoice_amount,
    invType: typeval && typeval.name,
    remarks: data.remarks 
  }
  editIndInvoice(invoiceData)
  .then(resp => {
    getIndInvoice(group_id)
      .then(resp => setInvoice(resp.data))
    })
    .catch(err => {
       console.log("Error", err);
    })
 }

 const validateForm = (aaa) => {
  const amount = parseFloat(aaa.invoice_amount) + parseFloat(aaa.cgst || 0) + parseFloat(aaa.sgst || 0) + parseFloat(aaa.igst || 0);
    const errors = [];
    if(aaa.customer_name != aaa.vendor_name){
      errors.customer_name = "Mismatch in Vendor Name as per the database";
    }
    if(isNaN(amount)){
      errors.invoice_amount = "The Invoice Amount is not a number";
    }
    if(addInvAmount != amount){
     errors.invoice_amount = "Invoice Amount does not match with sum of items amount"; 
    }
    if( (!aaa.invType) && (!typeval) ){
      errors.typeval = "Please select Invoice type";
    }
    return errors;
  }

 const approveInvoice = () => {
   const aaa = {
    vendor_name: data.vendor_name,
    customer_name: data.customer_name,
    invoice_amount: data.invoice_amount,
    cgst: data.cGst,
    sgst: data.sGst,
    igst: data.iGst,
    invType: data.invType
   }
   const errors = validateForm(aaa);
    if(Object.keys(errors).length) {
     setError(errors);
    return;
  }
   
   const dataa = {
    id: invoice_id,
    invoice_no: data.invoice_no,
    customer_name: data.customer_name,
    invoice_date: aaaDate.invoice_date,
    email_address: data.email_address,
    gst_no: data.gst_no,
    cgst: data.cGst,
    sgst: data.sGst,
    igst: data.iGst,
    invoice_amount: data.invoice_amount,
    remarks: data.remarks,
    status: 'VendorReviewed',
    temp_id: data.temp_id,
    vendor_id: data.vendor_name,
    apr_date: new Date(),
    invType: typeval && typeval.name
   }
    editIndInvoice(dataa)
    .then(resp => {
      getIndInvoice(group_id)
        .then(resp => setInvoice(resp.data))
        toast.success("Invoice successfully reviewed !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 2000})
        props.history.push(`${process.env.PUBLIC_URL}/vdashboard`)
      })
      .catch(err => {
         console.log("Error", err);
      })
 }

 const rejectInvoice = () => {
  const dataa = {
    id: invoice_id,
    status: 'Rejected',
    temp_id: data.temp_id
   }
   editIndInvoice(dataa)
   .then(resp => {
    getIndInvoice(group_id)
      .then(resp => setInvoice(resp.data))
      toast.warn("Invoice Rejected !!!", {position: toast.POSITION.TOP_CENTER, autoClose: 2000})
      props.history.push(`${process.env.PUBLIC_URL}/invoice`)
    })
    .catch(err => {
       console.log("Error", err);
    })
 }

 return (
  <Fragment>
    <div className="container-fluid">
     <div className="row mt-3">
      <div className="col-sm-12 col-xl-6 text-left">
       <button className="btn btn-pill btn-info-gradien btn-lg" type="button" onClick={handleBack}>Back</button>
      </div>
      <div className="col-sm-12 col-xl-6 text-right">
       {/*
       <button className="btn btn-pill btn-danger-gradien btn-lg" type="button" onClick={rejectInvoice}>
        <ThumbsDown/> Reject
       </button>
     */}
       <button className="btn btn-pill btn-secondary-gradien btn-lg" type="button" onClick={approveInvoice}>
        <ThumbsUp/>Approve
       </button>
      </div>
     </div>
     <div className="row m-t-15">
      <div className="col-sm-12 col-xl-7">
       <div className="card">
        <div className="card-body" style={{backgroundColor: 'paleturquoise'}}>
         <div className="media">
          <div className="media-body">
            <h4 className="f-w-600 text-center">
                <u>Invoice Details</u>
            </h4>
          </div>
         </div>
         <div className="job-description">
           <form className="form theme-form">
            <div className="row">
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Invoice Date:</label>
                <DatePicker className="form-control"
                  selected={new Date(aaaDate.invoice_date)}
                  value={new Date(aaaDate.invoice_date)}
                  onChange={handleDateChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                />
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
               <label htmlFor="exampleFormControlInput1">Invoice No:</label>
                <input className="form-control" name="invoice_no" type="text" 
                 value={data.invoice_no} onChange={handleChange} autoComplete="off"/>
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Selected Customer:</label>
                  <input className="form-control" name="vendor_name" type="text" 
                   value={data.vendor_name} disabled />
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Customer Name:</label>
                <input className="form-control" name="customer_name" type="text" 
                  value={data.customer_name} onChange={handleChange} autoComplete="off"/>
                <FormHelperText className="text-danger">{errors.customer_name}</FormHelperText>
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">GSTIN:</label>
                  <input className="form-control" name="gst_no" type="text" 
                   value={data.gst_no} onChange={handleChange} autoComplete="off"/>
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Invoice Amount:</label>
                  <input className="form-control" name="invoice_amount" type="text" 
                   value={data.invoice_amount} onChange={handleChange} autoComplete="off"/>
                  <FormHelperText className="text-danger">{errors.invoice_amount}</FormHelperText>
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">CGST:</label>
                  <input className="form-control" name="cGst" type="text" 
                   value={data.cGst} onChange={handleChange} autoComplete="off"/>
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">SGST:</label>
                  <input className="form-control" name="sGst" type="text" 
                   value={data.sGst} onChange={handleChange} autoComplete="off"/>
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">IGST:</label>
                  <input className="form-control" name="iGst" type="text" 
                   value={data.iGst} onChange={handleChange} autoComplete="off"/>
              </div>
             </div>
             <div className="col-sm-12 col-md-6">
              <div className="form-group">
               <label htmlFor="message-exampleFormControlInput1">Invoice Type:</label>
                <Autocomplete
                  freeSolo
                  options={invoiceType}
                  getOptionLabel={option => option.name}
                  value={typeval}                      
                  onChange={(event, newValue) => {
                    setTypeVal(newValue);
                  }}            
                  renderInput={params => (
                    <TextField {...params} className="m-t-0" margin="dense" variant="outlined" helperText="Please choose Type" fullWidth />
                  )}
                />
                <FormHelperText className="text-danger">{errors.typeval}</FormHelperText>
              </div>
             </div>
             <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <label htmlFor="exampleFormControlInput1">Email Address:</label>
                  <input className="form-control" name="email_address" type="text" 
                   value={data.email_address} onChange={handleChange} autoComplete="off"/>
              </div>
             </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                 <label htmlFor="exampleFormControlInput1">Remarks:</label>
                 <textarea className="form-control" name="remarks" rows={10}
                   value={data.remarks} onChange={handleChange}/>
                </div>
              </div>
            </div>
           </form>
         </div>
         <div className="media">
          <div className="media-body">
            <h4 className="f-w-600 text-center">
              <button className="btn btn-success" onClick={handleUpdate}>Update</button>
            </h4>
          </div>
         </div>
        </div>
         
      <div className="card-footer text-center" style={{backgroundColor: 'paleturquoise'}}>
        {/* Invoice Item details comes here */}
       <div className="media">
          <div className="media-body">
            <h4 className="f-w-600 text-center">
                <u>Invoice Item details</u>
            </h4>
          </div>
         </div>
         <div className="table-responsive-lg"> 
          <Table tableData={tableData} comonscol={comonscol} invoice_id={invoice_id}/>
         </div>
         {/*
       <div className="table-responsive-lg">
          <table className="table table-bordered table-hover">
           <thead className="table-info">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Description</th>
              <th scope="col">HSN</th>
              <th scope="col">GST</th>
              <th scope="col">Length</th>
              <th scope="col">Width</th>
              <th scope="col">Nos</th>
              <th scope="col">Qty</th>
              <th scope="col">Rate</th>
              <th scope="col">Amount</th>
            </tr>
           </thead>
           <tbody>
            {filterInvoiceItems.map((n,i)=> {
              return(
               <tr>
                <td>{i+1}</td>
                <td>{n.description}</td>
                <td>{n.hsn}</td>
                <td>{n.gst}</td>
                <td>{n.length}</td>
                <td>{n.width}</td>
                <td>{n.nos}</td>
                <td>{n.qty}</td>
                <td>{n.rate}</td>
                <td>{n.amount}</td>
               </tr>
              );
             })
            }
           </tbody>
          </table>
         </div>
     
         <Carousel>
          {filterInvoiceItems.map((n,i) => {
            let aaa = descrip;
            console.log("whattaaaa", aaa);
            return (
              <Item>
                <input type="text" value={aaa[i].description} onChange={() => handleChangeItems(n)}/>
              </Item>
            )
          })
         }
        </Carousel>
        */}

      </div>
     </div>
      </div>
      {/*
      <div className="col-sm-12 col-xl-6">
       <nav>
        <button className="btn btn-light" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
        <button className="btn btn-secondary" onClick={goToNextPage}>Next</button>
       </nav>
       <Document
          file={`${invoicePath}`}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <p>Page {pageNumber} of {numPages}</p>

        <Zoom
     img={`${invoicePath}`}
     zoomScale={3}
     width={840}
     height={720}
      />
      
      
       <img src={`${invoicePath}`} width="840" height="720" crossOrigin="anonymous"/>
      <file-viewer
       filename={`${data.inv_file}`}
       url= {invoicePath}
      ></file-viewer>
      </div>
      */}
    <div className="col-sm-12 col-xl-5" style={{ width: '840px', height: '720px' }}>
     {invoiceExtension === 'application/pdf' ?
      <div>
      <nav>
       <button className="btn btn-light" onClick={goToPrevPage}>Prev</button>&nbsp;&nbsp;
       <button className="btn btn-secondary" onClick={goToNextPage}>Next</button>
      </nav>
      <Document
        file={`${invoicePath}`}
        onLoadSuccess={onDocumentLoadSuccess}
       >
       <Page pageNumber={pageNumber} />
      </Document>
      <p>Page {pageNumber} of {numPages}</p>
      </div>
     :
      <PinchZoomPan>
          <img alt='Test Image' src={`${invoicePath}`} />
      </PinchZoomPan>
     }
    </div>
     </div>
    </div>
  </Fragment>
 );
}

export default ViewVendorInvoice;
